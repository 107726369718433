import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Currencies from 'components/Options/Currencies';
import Languages from 'components/Options/Languages';
import PaymentMethods from 'components/Options/PaymentMethods';
import Systems from 'components/Options/Systems';
import Terms from 'components/Options/Terms';

export default withAuth(withAlert()(class EntityVendor extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.carrier ||= false;
    this.state.item.entity_uuid ||= props.entity.uuid;
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  componentDidMount = async () => {
    await this.getTermsOptions(this.getItem().system_uuid);
  }

  formType = () => 'Vendor';

  getCrudType = () => 'entities/entity_vendors';

  successAlertMsg = () => this.successMsg('Vendor');

  system = () => (
    this.isNew() ? this.dropdownField('system') : (
      this.getDisplayFieldRow('system', 'System', this.getItem().system)
    )
  );

  getTermsOptions = async (systemUuid) => {
    const termOptions = [];

    if (HtmlHelpers.isNotEmpty(systemUuid)) {
      await Api.crudList(this.props.auth, 'credit/terms', {
        filtered: JSON.stringify([{ id: 'system_uuid', value: systemUuid }]),
        show_all: 'true',
      }).then((response) => {
        if (response.data !== undefined) {
          response.data.forEach((d) => {
            termOptions.push({ label: d.attributes.code, value: d.attributes.uuid });
          });
        }
      });
    }

    this.setState({ termOptions });
  };

  options = (num) => (
    {
      currency: {
        component: Currencies,
        field: 'currency_uuid',
        label: 'Currency',
        props: { isRequired: true, valueField: 'uuid' },
      },
      languages: {
        component: Languages,
        field: 'language_uuid',
        label: 'Language',
        props: { isRequired: true },
      },
      payment: {
        component: PaymentMethods,
        field: 'payment_method_uuid',
        label: 'Payment Method',
        props: { isRequired: true },
      },
      system: {
        component: Systems,
        field: 'system_uuid',
        label: 'System',
        props: { isRequired: true },
      },
      term: {
        component: Terms,
        field: 'terms_uuid',
        label: 'Term',
        data: this.state.termOptions,
        value: this.getItem().terms_uuid,
        props: { isRequired: true },
      },
    }
  );

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const value = HtmlHelpers.dig([event.target, 'value', 'value']) || event.target.value;

    if (name === 'system_uuid' && HtmlHelpers.isNotEmpty(value)) {
      this.setState((prevState) => ({ formKey: prevState.formKey + 1, systemName: event.target.value.label }));
      newData.locations = [{ active: true }];
      this.getTermsOptions(newData.system_uuid);
    }

    this.setVariables({ item: newData });
  };

  modalBodyRows = () => (
    <div>
      {this.system()}
      {this.dropdownField('term')}
      {this.dropdownField('payment')}
      {this.dropdownField('currency')}
      {this.dropdownField('languages')}
      {this.yesNoField('carrier', 'Carrier')}
      {this.activeField()}
    </div>
  )
}));
