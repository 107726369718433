import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class EntityGroups extends OptionsBaseApi {
  getApiName = () => 'entities/entity_groups';

  getApiParams = () => ({ order: 'name', query: 'active = true' });

  getDropdownLabelText = () => 'Entity Group';

  getDropdownFieldName = () => this.props.fieldName || 'entity_group_uuid';
}
