import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Notifications from 'components/Options/Notifications';

export default withAuth(withAlert()(class ContactNotification extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.contact_uuid = props.contact_uuid;
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  formType = () => 'Notification';

  getCrudType = () => 'entities/contact_notifications';

  successAlertMsg = () => this.successMsg('Notification');

  options = () => (
    {
      notification: {
        component: Notifications,
        field: 'notification_uuid',
        label: 'Notification',
      },
    }
  );

  modalBodyRows = () => (
    <div>
      {this.dropdownField('notification')}
      {this.activeField()}
    </div>
  )
}));
