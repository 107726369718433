const Columns = (context) => [
  {
    Header: 'System',
    accessor: 'systemname',
    className: 'text-left',
  },
  {
    Header: 'Location Code',
    accessor: 'bklocation',
    className: 'text-center',
  },
  {
    Header: 'Location Name',
    accessor: 'locationdescription',
    className: 'text-left',
  },
];

export default Columns;
