import React from 'react';
import { withAuth } from '@okta/okta-react';
import { Col, Row } from 'reactstrap';
import { Api, CrudList, UserGroups } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/CreditWatchHold';

export default withAuth(class CreditWatchHoldListContainer extends CrudList {
  constructor(props) {
    super(props);
    this.getLastUpdated();
  }

  getColumns = () => Columns(this);

  getListApi = () => 'credit/customers';

  getListParams = () => ({ show_all_status: 'true', hold_watch: 'Y' })

  getStorageName = () => 'otto_entities_credit_watch_hold_list';

  getPageTitle = () => 'Customer - Credit Watch/Hold';

  expandTitle = () => true;

  indexTable = () => this.paginationTable();

  loadOnMount = () => '';

  getLastUpdated = () => {
    const data = { query: "name = 'customer_credit_hold_watch_updated_through'" };
    Api.crudList(this.props.auth, 'app_settings', data).then((response) => {
      this.setState({ lastUpdated: response.data[0].attributes.display_value });
    });
  }

  getPageSubtitle = () => {
    const subtitleText = 'This list contains active WM - Agris Customers that are on Hold or Watch status';
    const { lastUpdated } = this.state;
    return (
      <div>
        <Row>
          <Col md="10">
            {subtitleText}
          </Col>
          <Col md="2" className="text-right">
            {lastUpdated ? `As of: ${lastUpdated}` : ''}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            **Oldest Date - Excludes Finance Charges
          </Col>
        </Row>
      </div>
    );
  };

  canDownloadCsv = () => true;

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
});
