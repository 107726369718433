import React from 'react';
import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Locations';

export default withAuth(class LocationListContainer extends CrudList {
  auditLogModelType = () => 'Entities::Location';

  loadOnMount = () => {}

  indexTable = () => this.paginationTable();

  getColumns = () => Columns(this);

  getStorageName = () => 'otto_entities_locations_list';

  getListApi = () => 'entities/locations';

  getPageTitle = () => 'Locations';

  getWidthCenter = () => 8;

  getWidthLeft = () => 2;

  getWidthRight = () => 2;

  canDownloadCsv = () => true;
});
