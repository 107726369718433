import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from 'locales/en.json';
import es from 'locales/es.json';

const resources = {
  en: { translation: en },
  es: { translation: es },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,
    lng: 'en',
    interpolation: { escapeValue: false },
  });

export default i18n;
