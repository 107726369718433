import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

import CreditReferenceTypes from 'components/Options/CreditReferenceTypes';

export default withAuth(withAlert()(class EntityReferenceForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.entity_uuid ||= this.props.entity_uuid;
  }

  formType = () => 'Entity Reference';

  getCrudType = () => 'entities/entity_references';

  successAlertMsg = () => this.successMsg('Reference');

  options = () => (
    {
      referenceType: {
        component: CreditReferenceTypes,
        field: 'credit_reference_type_uuid',
        label: 'Credit Reference Type',
      },
    }
  )

  modalBodyRows = () => (
    <div>
      {this.dropdownField('referenceType')}
      {this.getTextareaRow('notes', 'Notes', true)}
    </div>
  )
}));
