import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Contacts';

export default withAuth(class ContactsContainer extends CrudList {
  auditLogModelType = () => 'Entities::Contact';

  getColumns = () => Columns(this);

  getListApi = () => 'entities/entity_contacts';

  getStorageName = () => 'otto_entities_contacts_list';

  getPageTitle = () => 'Contacts';

  canDownloadCsv = () => true;

  indexTable = () => this.paginationTable();

  getWidthCenter = () => 12;

  getWidthLeft = () => 2;

  getWidthRight = () => 2;
});
