import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

import Customers from 'components/Options/Customers';

export default withAuth(withAlert()(class CustomerCreditHoldExclusionForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  formType = () => 'Credit Hold Exclusion';

  getCrudType = () => 'credit/customer_credit_hold_exclusions';

  successAlertMsg = () => this.successMsg('Customer Credit Hold Exclusion');

  getLabelColumns = () => 3;

  getFieldColumns = () => 8;

  options = () => (
    {
      customer: {
        component: Customers,
        field: 'customer_uuid',
        label: 'Customer',
      },
    }
  );

  customerName = () => `${this.getItem().name_with_ref} (${this.getItem().system_name})`;

  modalBodyRows = () => (
    <div>
      {
        this.isNew()
          ? this.dropdownField('customer')
          : this.getDisplayFieldRow('name_with_ref', 'Customer', this.customerName())
      }
      { this.activeField() }
    </div>
  )
}));
