import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Currencies extends OptionsBaseApi {
  getApiName = () => 'entities/currencies';

  getApiParams = () => ({ query: 'active=true' });

  getDropdownLabelText = () => this.props.fieldLabel || 'Currency';

  getDropdownFieldName = () => this.props.fieldName || 'currency_uuid';

  getValueFieldName = () => this.props.valueField || 'name';
}
