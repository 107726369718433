import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class VolumeMeasurements extends OptionsBaseApi {
  getApiName = () => 'credit/volume_measurements';

  getDropdownLabelText = () => 'Measurement';

  getApiParams = () => ({ order: 'name', query: 'active = true' });

  getDropdownFieldName = () => this.props.fieldName;
}
