import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    Header: 'Code',
    accessor: 'code',
    className: 'text-center',
  },
  {
    Header: 'Max Quantity (kg)',
    accessor: 'max_quantity',
    className: 'text-right',
  },
  {
    Header: 'Language',
    accessor: 'language',
    className: 'text-center',
  },
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
