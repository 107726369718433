import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Languages';
import ItemForm from 'components/Forms/Language';

export default withAuth(class LanguagesContainer extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/languages';

  getStorageName = () => 'otto_languages_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Languages';

  getAddText = () => 'Create Languages';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Entities::Language';
});
