import React from 'react';
import {
  Col, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/ContactNotifications';
import ItemForm from 'components/Forms/ContactNotification';

export default class ContactNotifications extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/contact_notifications';

  getListParams = () => ({ query: `contact_uuid='${this.contactUuid()}'` });

  contactUuid = () => this.props.data[this.props.editItem]?.uuid;

  contactName = () => this.props.data[this.props.editItem]?.name;

  getStorageName = () => 'otto_contact_notifications_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Notifications';

  getAddText = () => 'Add Notification';

  auditLogModelType = () => 'Entities::ContactNotification';

  getTableClassSettings = () => 'otto__short_table';

  createButton = () => this.createButtonSmall();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  canDownloadCsv = () => false;

  updateData = () => {
    this.setState({ showModal: false, selectedItem: null, loading: true });
    this.refreshAfterUpdate();
    this.props.updateData();
  }

  getForm = () => {
    const TagName = this.getFormTag();
    return this.state.showModal ? (
      <TagName
        contact_uuid={this.contactUuid()}
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItem}
        showModal={this.state.showModal}
        closeModal={this.handleCloseModal}
        updateData={this.updateData}
      />
    ) : '';
  }

  getContent = () => (
    <Modal
      className="otto__modal-l"
      size="lg"
      isOpen={this.props.showModal}
      toggle={this.props.closeModal}
      backdrop="static"
    >
      <ModalHeader toggle={this.props.closeModal}>
        {this.contactName()}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6" xs="12">
            <h4>
              Notifications (
              {this.state.loading ? 'loading' : this.state.data.length}
              )
            </h4>
          </Col>
          <Col md="6" xs="12" className="text-right">
            { this.isAddAdmin() ? this.createButton() : '' }
          </Col>
        </Row>
        <hr />
        { this.indexTable() }
        { this.itemForm() }
        { this.auditLogModal() }
      </ModalBody>
    </Modal>
  );
}
