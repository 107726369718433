import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/HoldReason';

export default withAuth(class HoldReasonsContainer extends CrudList {
  auditLogModelType = () => 'Credit::HoldReason';

  getColumns = () => this.sharedNameColumns(true);

  getFormTag = () => ItemForm;

  getListApi = () => 'credit/hold_reasons';

  getStorageName = () => 'otto_hold_reasons_list';

  getAddGroups = () => ['credit_admin'];

  getEditGroups = () => ['credit_admin'];

  getPageTitle = () => 'Hold Reasons';

  canDownloadCsv = () => true;

  getAddText = () => 'Create Hold Reason';
});
