import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class CreditReferenceTypes extends OptionsBaseApi {
  getDropdownLabelText = () => 'Credit Reference Type';

  getDropdownFieldName = () => 'credit_reference_type_uuid';

  getApiParams = () => ({ query: 'active = true' });

  getApiName = () => 'credit/credit_reference_types';

  getValueFieldName = () => 'uuid';
}
