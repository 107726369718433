import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Term';
import ItemForm from 'components/Forms/Term';

export default withAuth(class TermsContainer extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'credit/terms';

  getStorageName = () => 'otto_credit_terms_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Terms';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Credit::Term';

  getAddText = () => 'Create Term';

  loadOnMount = () => '';

  indexTable = () => this.paginationTable();

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
});
