import { ColumnHelpers, Links } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Reference',
    accessor: 'reference',
    Cell: (row) => Links.getLink('entities', `entity/view/${row.original.entity_uuid}`, row.original.reference),
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Currency',
    accessor: 'currency_name',
    className: 'text-center',
    width: 150,
  },
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    id: 'entity_group__name',
    Header: 'Entity Group',
    accessor: 'entity_group_name',
    className: 'text-left',
    width: 300,
  },
  {
    id: 'counterparty_type__name',
    Header: 'Counterparty Type',
    accessor: 'counterparty_type',
    className: 'text-left',
    width: 175,
  },
  {
    id: 'entity__tax_location',
    Header: 'Tax Location',
    accessor: 'tax_location',
    className: 'text-left',
    width: 120,
  },
  {
    id: 'bill_to_location__region_code',
    Header: 'Zip Code',
    accessor: 'region_code',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'VAT',
    accessor: 'vat',
    className: 'text-left',
    width: 100,
  },
  {
    Header: 'CIF',
    accessor: 'cif',
    className: 'text-left',
    width: 100,
  },
  ColumnHelpers.activeInactiveColumn(),
];

export default Columns;
