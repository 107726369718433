import { AppJs } from '@westernmilling/eu_react_shared';

import ContactsContainer from 'containers/ContactListContainer';
import CounterpartyTypesContainer from 'containers/CounterpartyTypesContainer';
import CountriesListContainer from 'containers/CountriesListContainer';
import CountryRiskRatingsListContainer from 'containers/CountryRiskRatingsListContainer';
import CreditLocationsContainer from 'containers/CreditLocationsContainer';
import CreditRatingsContainer from 'containers/CreditRatingsContainer';
import CreditReferenceTypesContainer from 'containers/CreditReferenceTypesContainer';
import CreditSectorsContainer from 'containers/CreditSectorsContainer';
import CreditStatusesContainer from 'containers/CreditStatusesContainer';
import CreditWatchHoldListContainer from 'containers/CreditWatchHoldListContainer';
import CurrenciesContainer from 'containers/CurrenciesContainer';
import CustomerCreditHoldExclusionsContainer from 'containers/CustomerCreditHoldExclusionsContainer';
import EntityGroupListContainer from 'containers/EntityGroupListContainer';
import EntityWithEntityGroupListContainer from 'containers/EntityWithEntityGroupListContainer';
import EntityNoteCategoryListContainer from 'containers/EntityNoteCategoryListContainer';
import EntityListContainer from 'containers/EntityListContainer';
import EntityViewContainer from 'containers/EntityViewContainer';
import HoldReasonsContainer from 'containers/HoldReasonsContainer';
import LanguagesContainer from 'containers/LanguagesContainer';
import LocationsListContainer from 'containers/LocationsListContainer';
import PaymentMethodsContainer from 'containers/PaymentMethodsContainer';
import TermsContainer from 'containers/TermsContainer';
import VolumeMeasurementsListContainer from 'containers/VolumeMeasurementsListContainer';
import TmsUserContainer from 'containers/TmsUserContainer';

export default class App extends AppJs {
  defaultRoute = () => EntityListContainer;

  routes = () => [
    { path: '/contacts', component: ContactsContainer },
    { path: '/counterparty_types', component: CounterpartyTypesContainer },
    { path: '/countries', component: CountriesListContainer },
    { path: '/country_risk_ratings', component: CountryRiskRatingsListContainer },
    { path: '/credit_locations', component: CreditLocationsContainer },
    { path: '/credit_ratings', component: CreditRatingsContainer },
    { path: '/credit_reference_types', component: CreditReferenceTypesContainer },
    { path: '/credit_sectors', component: CreditSectorsContainer },
    { path: '/credit_statuses', component: CreditStatusesContainer },
    { path: '/credit_watch_hold', component: CreditWatchHoldListContainer },
    { path: '/customer_credit_hold_exclusions', component: CustomerCreditHoldExclusionsContainer },
    { path: '/currencies', component: CurrenciesContainer },
    { path: '/entities', component: EntityListContainer },
    { path: '/entity_groups', component: EntityGroupListContainer },
    { path: '/entity_groups/:uuid/:name', component: EntityWithEntityGroupListContainer },
    { path: '/entity_note_categories', component: EntityNoteCategoryListContainer },
    { path: '/entity/view/:uuid', component: EntityViewContainer },
    { path: '/hold_reasons', component: HoldReasonsContainer },
    { path: '/languages', component: LanguagesContainer },
    { path: '/locations', component: LocationsListContainer },
    { path: '/payment_methods', component: PaymentMethodsContainer },
    { path: '/terms', component: TermsContainer },
    { path: '/volume_measurements', component: VolumeMeasurementsListContainer },
    { path: '/tms_users', component: TmsUserContainer },
  ];
}
