import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  CrudForm, FieldTranslations, FieldTranslationFormFields, HtmlHelpers,
} from '@westernmilling/eu_react_shared';

import Systems from 'components/Options/Systems';
import Languages from 'components/Options/Languages';

export default withAuth(withAlert()(class TermForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.days_until_hold ||= '0';
    this.state.item.new_name_translations = (
      FieldTranslationFormFields.getTranslations(props, this.state.item, 'name_translations', 'name')
    );
  }

  formType = () => 'Term';

  getCrudType = () => 'credit/terms';

  successAlertMsg = () => this.successMsg('Term');

  getModalClassName = () => 'otto__modal-l';

  buildApiVariables = () => ({
    ...this.getItem(),
    name: this.getItem().new_name_translations[0].translation,
  });

  addAnotherRow = (items, field) => this.setState((prevState) => (
    FieldTranslationFormFields.addAnotherRow(prevState, items, field)));

  removeNameTranslation = (idx) => this.setVariables(
    FieldTranslationFormFields.removeTranslation(this.getItem(), 'new_name_translations', idx),
  );

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const value = HtmlHelpers.dig([event.target, 'value', 'value']) || event.target.value;

    if (name.includes('name_translation')) {
      newData.new_name_translations = (
        FieldTranslationFormFields.setTranslations(newData.new_name_translations, 'translation', name, value)
      );
    } else if (name.includes('name_language')) {
      newData.new_name_translations = (
        FieldTranslationFormFields.setTranslations(newData.new_name_translations, 'language_uuid', name, value)
      );
    }

    this.setVariables({ item: newData });
  }

  options = () => (
    {
      system: {
        component: Systems,
        field: 'system_uuid',
        label: 'System',
      },
    }
  )

  modalBodyRows = () => (
    <div>
      <FieldTranslations
        auth={this.props.auth}
        addAnotherRow={() => this.addAnotherRow('new_name_translations', 'name')}
        item={this.getItem()}
        onChange={this.onChange}
        language="English"
        languages={Languages}
        languageTitle="Language"
        labelColumns={this.getLabelColumns()}
        fieldColumns={this.getFieldColumns()}
        fieldName="Name"
        remove={this.removeNameTranslation}
        translations={this.getItem().new_name_translations}
        type="view"
      />
      {this.getTextFieldRow('code', 'Short Name')}
      {this.getTextFieldRow('agris_code', 'Agris Code', false)}
      {this.getNumericFieldRow('days_until_hold', 'Days Until Hold', false)}
      {this.dropdownField('system')}
    </div>
  )
}));
