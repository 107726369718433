import React from 'react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TmsUsers';

export default class TmsUsers extends CrudList {
  getColumns = () => Columns(this, true);

  getFormTag = () => '';

  getListApi = () => 'admin/users';

  getListParams = () => ({
    order: 'name',
    query: `provider = 'auth0' and user_entities.entity_uuid = '${this.props.uuid}'`,
  });

  getStorageName = () => 'otto_entity_tms_users_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => `TMS Users (${this.state.loading ? 'loading' : this.state.data.length})`;

  getAddText = () => 'Add TMS User';

  auditLogModelType = () => 'Admin::User';

  getTableClassSettings = () => 'otto__short_table';

  getContent = () => this.contentCollapsible();

  createButton = () => this.createButtonSmall();

  additionalFormProps = () => ({ entity_uuid: this.props.uuid, onEntity: true });

  canDownloadCsv = () => false;

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
}
