import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/EntityNoteCategory';

export default withAuth(class EntityNoteCategoryListContainer extends CrudList {
  getColumns = () => this.sharedNameColumns(true);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/entity_note_categories';

  getStorageName = () => 'otto_entity_note_categories_list';

  getPageTitle = () => 'Entity Note Categories';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  auditLogModelType = () => 'Entities::EntityNoteCategory';

  getAddText = () => 'Create Entity Note Category';

  canDownloadCsv = () => true;
});
