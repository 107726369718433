import React from 'react';
import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/TmsUsers';
import TmsUsers from 'components/Forms/TmsUsers';
import TmsUserEdit from 'components/Forms/TmsUserEdit';

export default withAuth(class TmsUserContainer extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => TmsUsers;

  getListApi = () => 'admin/users';

  getListParams = () => ({ order: 'name', query: "provider = 'auth0'" });

  getStorageName = () => 'otto_tms_users_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'TMS Users';

  getAddText = () => 'Create TMS User';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Admin::User';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  updateData = () => {
    this.setState({ showModal: false, selectedItem: null, loading: true });
    this.handleCloseModal('TmsEdit');
    this.refreshAfterUpdate();
  }

  editTmsUser = () => (
    this.state.showModalTmsEdit ? (
      <TmsUserEdit
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItemTmsEdit}
        showModal={this.state.showModalTmsEdit}
        closeModal={() => this.handleCloseModal('TmsEdit')}
        updateData={this.updateData}
        englishLanguageUuid={this.state.englishLanguageUuid}
        additionalFormProps={this.additionalFormProps()}
      />
    ) : ''
  );

  additionalContent = () => (
    <div>
      {this.editTmsUser()}
    </div>
  );
});
