import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/CreditLocations';

export default withAuth(class CreditLocationsContainer extends CrudList {
  getColumns = () => Columns(this);

  getListApi = () => 'data_warehouse/dimlocations';

  getStorageName = () => 'otto_credit_locations_list';

  getPageTitle = () => 'Credit Locations';
});
