import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { Col, Row } from 'reactstrap';
import { Api, WmToggleRadioField } from '@westernmilling/eu_react_shared';

export default withAuth(class SystemsActive extends Component {
  constructor(props) {
    super(props);
    this.state = { systems: {}, values: this.props.values };
  }

  async componentDidMount() {
    this.getSystems();
  }

  onChange = (event) => {
    const target = event.target;
    this.setState((prevState) => {
      const newData = { ...prevState.values };

      newData[target.name] ||= { uuid: null, name: prevState.systems[target.name].name };
      newData[target.name].active = target.value === 'true';
      newData[target.name].active_text = target.value === 'true' ? 'Yes' : 'No';
      this.props.updateSystemData(newData);
      return { values: newData };
    });
  }

  getSystems = async () => {
    const systems = {};
    await Api.crudList(this.props.auth, 'admin/systems', { query: 'active=true' }, '1')
      .then((response) => {
        if (response.data !== undefined) {
          response.data.forEach((d) => {
            systems[d.attributes.uuid] = d.attributes;
          });
        }
      });

    this.setState({ systems });
  }

  systemIsActive = (systemUuid) => (
    (this.state.values[systemUuid] || {}).active || false
  );

  systemLines = () => (
    Object.keys(this.state.systems).map((systemUuid) => {
      const system = this.state.systems[systemUuid];
      return (
        <Row key={system.uuid}>
          <Col md="12" xs="12">
            <WmToggleRadioField
              labelColumns="4"
              fieldColumns="5"
              text={system.name}
              htmlFor={system.uuid}
              name={system.uuid}
              field1={{ label: 'Yes', value: 'true' }}
              field2={{ label: 'No', value: 'false' }}
              value={this.systemIsActive(system.uuid)}
              onChange={this.onChange}
              isRequired
            />
          </Col>
        </Row>
      );
    })
  );

  render() {
    return (
      <div>
        <b>Systems</b>
        {this.systemLines()}
        <br />
      </div>
    );
  }
});
