import { HtmlHelpers, LinksActionData } from '@westernmilling/eu_react_shared';

export default class EntityView extends LinksActionData {
  menuItems = () => {
    const uuid = this.props.uuid;
    const info = this.props.linkInfo;
    return HtmlHelpers.isEmpty(uuid) ? [] : [
      this.editInfo(info.edit),
      this.legalAddressInfo(info.legal_address),
      this.billToInfo(info.bill_to),
      this.remitToInfo(info.remit_to),
    ];
  }

  editInfo = (info) => this.buildInfo(info, info.label, this.props.editEvent);

  billToInfo = (info) => this.buildInfo(info, info.label, this.props.editBillTo);

  remitToInfo = (info) => this.buildInfo(info, info.label, this.props.editRemitTo);

  legalAddressInfo = (info) => this.buildInfo(info, info.label, this.props.editLegalAddress);
}
