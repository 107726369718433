import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Systems from 'components/Options/Systems';

export default withAuth(withAlert()(class LocationSystem extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.location_uuid = this.props.location_uuid;
    this.state.item.system_reference ||= this.props.locationReference;
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  formType = () => 'System';

  getCrudType = () => 'entities/location_systems';

  successAlertMsg = () => this.successMsg('System');

  options = () => (
    {
      system: {
        component: Systems,
        field: 'system_uuid',
        label: 'System',
      },
    }
  );

  modalBodyRows = () => (
    <div>
      {this.dropdownField('system')}
      {this.getTextFieldRow('system_reference', 'System Reference', false)}
      {this.activeField()}
    </div>
  )
}));
