import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/CountryRiskRating';

export default withAuth(class CountryRiskRatingsListContainer extends CrudList {
  getColumns = () => this.sharedNameColumns(true);

  getFormTag = () => ItemForm;

  getListApi = () => 'credit/country_risk_ratings';

  getStorageName = () => 'otto_country_risk_ratings_list';

  getAddGroups = () => ['credit_admin'];

  getEditGroups = () => ['credit_admin'];

  getPageTitle = () => 'Country Risk Ratings';

  getAddText = () => 'Create Country Risk Rating';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Credit::CountryRiskRating';
});
