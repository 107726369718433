import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Users extends OptionsBaseApi {
  getApiName = () => 'admin/users';

  getApiParams = () => this.props.apiParams || ({ order: 'name' });

  getDropdownLabelText = () => this.props.fieldLabel || 'Supervisor';

  getDropdownFieldName = () => this.props.fieldName || 'user_uuid';
}
