import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class CurrencyForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  formType = () => 'Currency';

  getCrudType = () => 'entities/currencies';

  successAlertMsg = () => this.successMsg('Currency');

  modalBodyRows = () => (
    <div>
      { this.getTextFieldRow('name', 'Short Code', true, { maxLength: 3, minLength: 3 }) }
      { this.getTextFieldRow('description', 'Description') }
      { this.activeField() }
    </div>
  )
}));
