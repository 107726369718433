import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class HoldReasonForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  formType = () => 'Hold Reason';

  getCrudType = () => 'credit/hold_reasons';

  successAlertMsg = () => this.successMsg('Hold Reason');

  modalBodyRows = () => (
    <div>
      { this.getTextFieldRow('name', 'Name') }
      { this.activeField() }
    </div>
  )
}));
