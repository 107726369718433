import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class CreditRatings extends OptionsBaseApi {
  getApiName = () => 'credit/credit_ratings';

  getApiParams = () => ({ order: 'name', query: 'active = true' });

  getDropdownLabelText = () => 'Credit Ratings';

  getDropdownFieldName = () => this.props.fieldName || 'credit_rating_uuid';
}
