import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/CreditReferenceType';

export default withAuth(class CreditReferenceTypesContainer extends CrudList {
  auditLogModelType = () => 'Credit::CreditReferenceType';

  getColumns = () => this.sharedNameColumns(true);

  getFormTag = () => ItemForm;

  getListApi = () => 'credit/credit_reference_types';

  getStorageName = () => 'otto_credit_reference_types_list';

  getAddGroups = () => ['credit_admin'];

  getEditGroups = () => ['credit_admin'];

  getPageTitle = () => 'Credit Reference Types';

  canDownloadCsv = () => true;

  getAddText = () => 'Create Credit Reference Type';
});
