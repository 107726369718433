import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Locations from 'components/Options/EntityLocation';

export default withAuth(withAlert()(class LegalAddressForm extends CrudForm {
  formType = () => 'Legal Address';

  getCrudType = () => 'entities/entities';

  successAlertMsg = () => this.successMsg('Legal Address');

  options = () => (
    {
      location: {
        component: Locations,
        field: 'legal_address_uuid',
        label: 'Legal Address',
        props: { entityUuid: this.props.data[0].uuid },
        defaultData: this.defaultData(),
      },
    }
  );

  defaultData = () => {
    const entity = this.props.data[0];
    let data = null;
    if (HtmlHelpers.isNotEmpty(entity.legal_address_name)) {
      data = { text: entity.legal_address_name, id: entity.legal_address_uuid };
    }
    return data;
  }

  modalBodyRows = () => (
    <div>
      {this.dropdownField('location')}
    </div>
  )
}));
