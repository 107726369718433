import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/RelatedEntities';
import ItemForm from 'components/Forms/RelatedEntity';

export default class RelatedEntities extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/related_entities';

  getListParams = () => ({ query: `entity_uuid = '${this.props.uuid}'` });

  getStorageName = () => 'otto_entity_related_entities_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => `Related Entities (${this.state.loading ? 'loading' : this.state.data.length})`;

  getAddText = () => 'Add Related Entity';

  auditLogModelType = () => 'Entities::RelatedEntity';

  getTableClassSettings = () => 'otto__short_table';

  getContent = () => this.contentCollapsible();

  createButton = () => this.createButtonSmall();

  additionalFormProps = () => ({ entityUuid: this.props.uuid, usedUuids: this.usedUuids() });

  usedUuids = () => [this.props.uuid] + this.state.data.map((d) => d.uuid);

  canDownloadCsv = () => false;

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
}
