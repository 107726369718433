import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers, OptionsCountry } from '@westernmilling/eu_react_shared';

import Systems from 'components/Options/SystemsActive';

export default withAuth(withAlert()(class LocationForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.entity_uuid ||= this.props.entity_uuid;
    this.state.item.entity_reference ||= this.props.entity_reference;
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  options = () => (
    {
      country: {
        component: OptionsCountry,
        field: 'country',
        label: 'Country',
        currentValue: this.getItem().country,
        props: { isRequired: true },
      },
    }
  )

  formType = () => 'Location';

  getCrudType = () => 'entities/locations';

  successAlertMsg = () => this.successMsg('Location');

  updateSystemData = (data) => this.setState((prevState) => {
    const newData = { ...prevState.item };
    newData.systems_hash = data;
    return { item: newData };
  });

  reference = () => (
    this.isNew() ? '' : this.getDisplayFieldRow('reference', 'Reference', this.getItem().system_name)
  )

  number = () => (
    this.isNew() ? '' : this.getTextFieldRow('location_number', 'Location Number')
  )

  additionalValidations = () => {
    const errors = [];
    if (HtmlHelpers.isEmpty(this.getItem().region)) {
      errors.push('Province is not present');
    }
    if (HtmlHelpers.isEmpty(this.getItem().region_code)) {
      errors.push('Postal Code is not present');
    }
    this.setState({ errors });
    return errors;
  }

  modalBodyRows = () => (
    <div>
      { this.getDisplayFieldRow('reference', 'Reference', this.props.entity_reference) }
      { this.number() }
      { this.getTextFieldRow('name', 'Name', true) }
      { this.getTextFieldRow('street_address', 'Address Line 1', true) }
      { this.getTextFieldRow('additional_address_line', 'Address Line 2', false) }
      { this.getTextFieldRow('city', 'City', true) }
      { this.getTextFieldRow('region', 'Province', true) }
      { this.getTextFieldRow('region_code', 'Postal Code', true) }
      { this.dropdownField('country') }
      { this.activeField() }
    </div>
  )

  isTrueFalseInt = (field, label) => (
    this.getToggleRadioField({ label: 'Yes', value: '1' }, { label: 'No', value: '0' }, field, label)
  )
}));
