import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Button, Col, Collapse, Row,
} from 'reactstrap';
import {
  AddIcon, CaretToggle, CrudList, HtmlHelpers,
} from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/EntityReviews';
import ItemForm from 'components/Forms/EntityReview';
import ReviewNotesModal from 'components/Modals/ReviewNotesModal';

export default class EntityReviews extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/entity_reviews';

  getListParams = () => ({ query: `entity_reviews.entity_uuid = '${this.props.uuid}'` });

  getStorageName = () => 'otto_entity_reviews_list';

  getAddGroups = () => ['credit_admin'];

  getEditGroups = () => ['credit_admin'];

  getPageTitle = () => 'Reviews';

  getAddText = () => 'Add Review';

  auditLogModelType = () => 'Entities::EntityReview';

  getTableClassSettings = () => 'otto__short_table';

  canDownloadCsv = () => false;

  createButton = () => (
    <div>
      { this.getFormTag() === '' ? '' : (
        <AddIcon className="btn-sm otto__margin_btm_30" onClick={this.handleOpenModal} addText={this.getAddText()} />
      ) }
    </div>
  )

  getForm = () => {
    const TagName = this.getFormTag();
    return (
      <TagName
        entity_uuid={this.props.uuid}
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItem}
        showModal={this.state.showModal}
        closeModal={this.handleCloseModal}
        updateData={this.updateData}
      />
    );
  }

  openNoteModal = (options) => this.setState({ showNoteModal: true, noteOptions: options });

  closeNoteModal = () => { this.setState({ showNoteModal: false, noteOptions: {} }); };

  noteModal = () => (
    this.state.showNoteModal ? (
      <ReviewNotesModal
        closeModal={this.closeNoteModal}
        showModal={this.state.showNoteModal}
        options={this.state.noteOptions}
      />
    ) : ''
  );

  getContent = () => (
    <div>
      <hr />
      <Row>
        <Col md="6" xs="12">
          <h4>
            Reviews (
            {this.state.loading ? 'loading' : this.state.data.length}
            )
            &nbsp;&nbsp;&nbsp;
            <CaretToggle onClick={() => this.setState((prevState) => ({ open: !prevState.open }))} open={this.state.open} />
          </h4>
        </Col>
        <Col md="6" xs="12" className="text-right">
          { this.isAddAdmin() ? this.createButton() : '' }
        </Col>
      </Row>
      <Collapse isOpen={this.state.open}>
        { this.indexTable() }
      </Collapse>
      { this.itemForm() }
      { this.auditLogModal() }
      { this.noteModal() }
    </div>
  )
}
