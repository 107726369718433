import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  ColumnHelpers.activeInactiveColumn(),
];

export default Columns;
