import React from 'react';
import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/EntityGroups';
import ItemForm from 'components/Forms/EntityGroup';

export default withAuth(class EntityGroupListContainer extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/entity_groups';

  getStorageName = () => 'otto_entity_groups_list';

  getPageTitle = () => 'Entity Groups';

  getPageSubtitle = () => (
    <div>
      If a new Entity Group is need please email the &nbsp;
      <a href="mailto:mda@viserion.co">US Master Data Analyst</a>
    </div>
  );

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Entities::EntityGroup';
});
