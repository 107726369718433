import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Locations from 'components/Options/EntityLocation';

export default withAuth(withAlert()(class RemitToForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.payee_name ||= props.data[props.editItem].name;
  }

  formType = () => 'Remit To';

  getCrudType = () => 'entities/entities';

  successAlertMsg = () => this.successMsg('Remit To');

  getLabelColumns = () => 3;

  getFieldColumns = () => 8;

  options = () => (
    {
      location: {
        component: Locations,
        field: 'remit_to_location_uuid',
        label: 'Remit To',
        props: { entityUuid: this.props.data[0].uuid },
        defaultData: this.defaultData(),
      },
    }
  );

  defaultData = () => {
    const entity = this.props.data[0];
    let data = null;
    if (HtmlHelpers.isNotEmpty(entity.remit_to_name)) {
      data = { text: entity.remit_to_name, id: entity.remit_to_location_uuid };
    }
    return data;
  }

  modalBodyRows = () => (
    <div>
      {this.getTextFieldRow('payee_name', 'Payee Name')}
      {this.dropdownField('location')}
    </div>
  )
}));
