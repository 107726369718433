import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class LanguageForm extends CrudForm {
  formType = () => 'Language';

  getCrudType = () => 'entities/languages';

  successAlertMsg = () => this.successMsg('Language');

  modalBodyRows = () => (
    <div>
      { this.getTextFieldRow('name', 'Name') }
    </div>
  )
}));
