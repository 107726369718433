import React from 'react';
import { HtmlHelpers, TableFilters } from '@westernmilling/eu_react_shared';

const entityReferenceLink = (id, uuid) => {
  const base = `${process.env.REACT_APP_BASE_URL}/entities${process.env.REACT_APP_URL_ENV}/entity/view/${uuid}`;
  return <a href={base}>{id}</a>;
};

const Columns = (context) => [
  {
    Header: 'Reference',
    accessor: 'location_reference',
    Cell: (row) => entityReferenceLink(row.original.location_reference, row.original.entity_uuid),
    className: 'text-left',
    width: 160,
  },
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    id: 'address',
    Header: 'Address',
    accessor: 'address',
    Cell: (row) => (
      <div>
        <div>{row.original.street_address}</div>
        <div>{row.original.additional_address_line}</div>
        <div>
          {row.original.city}
          {', '}
          {row.original.region}
          {' '}
          {row.original.region_code}
        </div>
      </div>
    ),
    className: 'text-left',
  },
  {
    id: 'is_active',
    Header: 'Active',
    accessor: 'active_inactive_text',
    Filter: ({ filter, onChange }) => TableFilters.ActiveFilterOptions(filter, onChange),
    Cell: (row) => HtmlHelpers.inactiveRed(row.original.active_inactive_text),
    className: 'text-center',
    width: 100,
  },
];

export default Columns;
