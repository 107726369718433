import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Button, Col, Collapse, Row,
} from 'reactstrap';
import {
  AddIcon, CaretToggle, CrudList, HtmlHelpers,
} from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/EntityReferences';
import ItemForm from 'components/Forms/EntityReference';

export default class EntityReferences extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/entity_references';

  getListParams = () => ({ query: `entity_references.entity_uuid = '${this.props.uuid}'` });

  getStorageName = () => 'otto_entity_references_list';

  getAddGroups = () => ['credit_admin'];

  getEditGroups = () => ['credit_admin'];

  getPageTitle = () => 'References';

  getAddText = () => 'Add Reference';

  auditLogModelType = () => 'Entities::EntityReference';

  getTableClassSettings = () => 'otto__short_table';

  canDownloadCsv = () => false;

   createButton = () => (
     <div>
       { this.getFormTag() === '' ? '' : (
         <AddIcon className="btn-sm otto__margin_btm_30" onClick={this.handleOpenModal} addText={this.getAddText()} />
       ) }
     </div>
   )

  getForm = () => {
    const TagName = this.getFormTag();
    return (
      <TagName
        entity_uuid={this.props.uuid}
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItem}
        showModal={this.state.showModal}
        closeModal={this.handleCloseModal}
        updateData={this.updateData}
      />
    );
  }

  getContent = () => (
    <div>
      <hr />
      <Row>
        <Col md="6" xs="12">
          <h4>
            References (
            {this.state.loading ? 'loading' : this.state.data.length}
            )
            &nbsp;&nbsp;&nbsp;
            <CaretToggle onClick={() => this.setState((prevState) => ({ open: !prevState.open }))} open={this.state.open} />
          </h4>
        </Col>
        <Col md="6" xs="12" className="text-right">
          { this.isAddAdmin() ? this.createButton() : '' }
        </Col>
      </Row>
      <Collapse isOpen={this.state.open}>
        { this.indexTable() }
      </Collapse>
      { this.itemForm() }
      { this.auditLogModal() }
    </div>
  )
}
