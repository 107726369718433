import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Systems extends OptionsBaseApi {
  getApiName = () => 'admin/systems';

  getApiParams = () => ({ order: 'name' });

  getDropdownLabelText = () => 'System';

  getDropdownFieldName = () => 'system_uuid';
}
