import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export default class ColumnButton extends Component {
  onClick = () => {
    this.props.onClick(this.props.data);
  };

  render() {
    return (
      <Button
        disabled={this.props.disabled}
        outline
        color={this.props.color}
        size="sm"
        onClick={this.onClick}
        className={this.props.className}
      >
        <span className="small">{this.props.text}</span>
      </Button>
    );
  }
}

ColumnButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

ColumnButton.defaultProps = {
  disabled: false,
  color: 'secondary',
};
