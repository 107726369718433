import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Button, Col, Collapse, Row,
} from 'reactstrap';
import {
  CaretToggle, CrudList, HtmlHelpers, WmReactTable,
} from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/EntityVendors';
import ItemForm from 'components/Forms/EntityVendor';

export default class EntityVendors extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/entity_vendors';

  getListParams = () => ({ query: `entity_uuid = '${this.props.item.uuid}'` });

  getStorageName = () => 'otto_entity_vendors_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Vendors';

  auditLogModelType = () => 'Entities::EntityVendor';

  getAddText = () => 'Add Vendor';

  getTableClassSettings = () => 'otto__short_table';

  hasRemitTo = () => HtmlHelpers.isNotEmpty(this.props.item.remit_to_location_uuid);

  addRemitToMessage = () => (
    <div style={{ paddingTop: '8px' }}>
      { this.hasRemitTo() ? '' : 'You must add a Remit to Location before adding a Vendor' }
    </div>
  );

  createButton = () => (
    <Button className="btn-sm" color="info" title="Add" outline onClick={this.handleOpenModal} disabled={!this.hasRemitTo()}>
      {this.getAddText()}
    </Button>
  );

  getForm = () => {
    const TagName = this.getFormTag();
    return (
      <TagName
        entity={this.props.item}
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItem}
        showModal={this.state.showModal}
        closeModal={this.handleCloseModal}
        updateData={this.updateData}
      />
    );
  }

  getContent = () => (
    <div>
      <hr />
      <Row>
        <Col md="4" xs="12">
          <h4>
            Vendors (
            {this.state.loading ? 'loading' : this.state.data.length}
            )
            &nbsp;&nbsp;&nbsp;
            <CaretToggle onClick={() => this.setState((prevState) => ({ open: !prevState.open }))} open={this.state.open} />
          </h4>
        </Col>
        <Col md="6" className="text-right">
          { this.addRemitToMessage() }
        </Col>
        <Col md="2" xs="12" className="text-right">
          { this.isAddAdmin() ? this.createButton() : '' }
        </Col>
      </Row>
      <Collapse isOpen={this.state.open}>
        <WmReactTable
          ref={this.tableRef}
          innerRef={this.innerTableRef}
          className={this.getTableClassSettings()}
          data={this.state.data}
          columns={this.getColumns()}
          filtered={this.state.filtered}
        />
      </Collapse>
      { this.itemForm() }
      { this.auditLogModal() }
    </div>
  )
}
