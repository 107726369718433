import { OptionsBaseList } from '@westernmilling/eu_react_shared';

export default class GuarantyTypes extends OptionsBaseList {
  getDropdownLabelText = () => 'Guaranty Type';

  getDropdownFieldName = () => 'guaranty_type';

  getOptions = () => (
    [
      { value: 'Personal', label: 'Personal' },
      { value: 'Corporate', label: 'Corporate' },
      { value: 'None', label: 'None' },
    ]
  );
}
