import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Terms extends OptionsBaseApi {
  componentDidMount = async () => {};

  getApiName = () => 'credit/terms';

  getApiParams = () => this.props.apiParams || [];

  getDropdownLabelText = () => 'Term';

  getDropdownFieldName = () => 'terms_uuid';

  getLabelFieldName = () => 'code';
}
