import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import { Api, CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class EntityGroupViewForm extends CrudForm {
  constructor(props) {
    super(props);
    this.getItemFromApi();
  }

  entityGroupUuidParam = () => this.props.item.entity_group_uuid;

  formType = () => {
    const baseLink = `${process.env.REACT_APP_BASE_URL}/entities${process.env.REACT_APP_URL_ENV}/`;
    const params = `entity_groups/${this.props.item.entity_group_uuid}/${this.getItem().name}`;
    const link = (
      <a href={baseLink + params}>
        {this.getItem().name}
      </a>
    );
    return (
      <div>
        Entity Group:
        &nbsp;
        {link}
      </div>
    );
  }

  getCrudType = () => 'entity_groups';

  headerTitle = () => '';

  getItemFromApi = () => {
    Api.crudView(this.props.auth, 'entities/entity_groups', this.entityGroupUuidParam(), 1)
      .then((response) => {
        if (response.data !== undefined) {
          this.setState({
            item: response.data.attributes,
          });
        }
      });
  }

  limitDisplay = (field) => (
    this.getItem().entity_group_limits[field].map((limit) => (
      <div>
        {limit}
      </div>
    ))
  );

  volumeDisplay = () => {
    const value = this.getItem().entity_group_limits.volume_limits.map((vol) => (
      <div>
        {vol}
      </div>
    ));
    return this.getRowWithValue('Group Volume Limits', value);
  }

  viewPage = () => (
    <div>
      { this.modalBodyRows() }
    </div>
  )

  modalBodyRows = () => (HtmlHelpers.isNotEmpty(this.getItem()) ? (
    <div>
      <hr />
      <Row>
        <Col md="6" xs="12">
          <h4>
            {this.formType()}
          </h4>
        </Col>
      </Row>
      <hr />
      <br />
      <Row>
        <Col md={4} sm={12}>
          {this.getRowWithValue('Group Credit Limit', this.limitDisplay('credit_limit'))}
          {this.getRowWithValue('Group MTM Limit', this.limitDisplay('mtm_limit'))}
        </Col>
        <Col md={4} sm={12}>
          {this.volumeDisplay()}
        </Col>
        <Col md={4} sm={12}>
          {this.getRowWithValue('Group Tenor Limit', this.getItem().entity_group_limits.tenor_limit)}
          {this.getRowWithValue('Credit Insurance Limit', this.limitDisplay('credit_insurance_limit'))}
        </Col>
      </Row>
      <br />
      <hr />
    </div>
  ) : '');
}));
