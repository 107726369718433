import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class CreditSectors extends OptionsBaseApi {
  getApiName = () => 'credit/credit_sectors';

  getApiParams = () => ({ order: 'name', query: 'active = true' });

  getDropdownLabelText = () => 'Sector/ Industry';

  getDropdownFieldName = () => this.props.fieldName || 'credit_sector_uuid';
}
