import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/CustomerCreditHoldExclusions';
import ItemForm from 'components/Forms/CustomerCreditHoldExclusion';

export default withAuth(class CustomerCreditHoldExclusionsContainer extends CrudList {
  auditLogModelType = () => 'Credit::CustomerCreditHoldExclusion';

  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'credit/customer_credit_hold_exclusions';

  getStorageName = () => 'otto_customer_credit_hold_exclusions_list';

  getAddGroups = () => ['credit_admin'];

  getEditGroups = () => ['credit_admin'];

  getPageTitle = () => 'Automated Credit Hold Exclusions';

  canDownloadCsv = () => true;

  getAddText = () => 'Add Credit Hold Exclusion';

  getWidthCenter = () => 8;

  getWidthLeft = () => 2;

  getWidthRight = () => 2;
});
