import React from 'react';
import { ColumnHelpers } from '@westernmilling/eu_react_shared';

import ColumnButton from 'helpers/columns/ColumnButton';

const Columns = (context) => [
  {
    Header: 'Reference',
    accessor: 'location_reference',
    className: 'text-center',
    width: 125,
  },
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    id: 'address',
    Header: 'Address',
    accessor: (d) => (
      <pre>
        {d.address}
      </pre>
    ),
    className: 'text-left',
  },
  {
    id: 'system__name',
    Header: 'Systems',
    accessor: (d) => d.active_systems.map((s) => (
      <div key={`system_${s.name}`}>
        {s.name}
      </div>
    )),
    className: 'text-left',
    width: 200,
  },
  ColumnHelpers.activeInactiveColumn(),
  ColumnHelpers.auditLogLink(context),
  {
    id: 'selection',
    Header: 'Edit',
    Cell: (row) => (
      <div>
        <ColumnButton
          className="otto__margin_btm_10"
          onClick={() => context.selectItem(row.index)}
          text="Location"
        />
        <ColumnButton
          onClick={() => context.openSystemModal(row.index)}
          text="Systems"
        />
      </div>
    ),
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 100,
    show: context.props.admin,
  },
];

export default Columns;
