import React from 'react';
import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const baseLink = `${process.env.REACT_APP_BASE_URL}/entities${process.env.REACT_APP_URL_ENV}`;

const Columns = (context) => [
  {
    Header: 'Reference',
    accessor: 'reference',
    Cell: (row) => (
      <a href={`${baseLink}/entity/view/${row.original.uuid}`}>{row.original.reference}</a>
    ),
    className: 'text-left',
    width: 100,
  },
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    Header: 'Position',
    accessor: 'position',
    className: 'text-left',
  },
  {
    Header: 'Email',
    accessor: 'email_address',
    className: 'text-left',
  },
  {
    Header: 'Phone',
    accessor: 'phone_1',
    className: 'text-left',
    width: 120,
  },
  {
    id: 'phone_1_text',
    Header: 'Can Text',
    accessor: 'phone_1_can_text',
    className: 'text-center',
    width: 80,
  },
  {
    Header: 'Alt Phone',
    accessor: 'phone_2',
    className: 'text-left',
    width: 120,
  },
  {
    id: 'phone_2_text',
    Header: 'Can Text',
    accessor: 'phone_2_can_text',
    className: 'text-center',
    width: 80,
  },
  {
    Header: 'Fax Number',
    accessor: 'fax_number',
    className: 'text-left',
    width: 120,
  },
  {
    Header: 'Systems',
    accessor: 'system_names',
    className: 'text-left',
  },
  ColumnHelpers.activeInactiveColumn(),
];

export default Columns;
