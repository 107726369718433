import { Api, HtmlHelpers, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Languages extends OptionsBaseApi {
  getApiName = () => 'entities/languages';

  getDropdownLabelText = () => this.props.fieldLabel || 'Language';

  getDropdownFieldName = () => this.props.fieldName || 'language_uuid';

  getDropdownLabelText = () => this.props.fieldLabel;

  getStyles = () => ({ menu: (base) => ({ ...base, margin: 0, padding: 0 }) });

  getItemList = () => {
    let items = this.state.items;
    const languages = this.props.usedLanguages || [];
    const used = languages.map((usedLang) => usedLang.language_uuid).filter((ul) => ul !== undefined);
    if (HtmlHelpers.isNotEmpty(used)) {
      items = items.filter((lang) => !used.includes(lang.value));
    }
    return items;
  };

  getValue = () => {
    let details = this.props.multi ? [] : '';
    this.state.items.forEach((item) => {
      if (this.props.multi && this.props.multiUuidOnly !== true) {
        details = this.props.currentValue;
      } else if (this.props.multi && this.props.currentValue.includes(item.value)) {
        details.push(item);
      } else if (item.value === this.props.currentValue) {
        details = item;
      }
    });
    return details;
  }
}
