import React from 'react';
import { withAuth } from '@okta/okta-react';
import { Button, Col, Row } from 'reactstrap';
import { CrudList, PageTitle } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Entities';

export default withAuth(class EntityWithEntityGroupListContainer extends CrudList {
  getColumns = () => Columns(this, false);

  getListApi = () => 'entities/entities';

  getListParams = () => ({ filtered: { id: 'entity_group_uuid', value: this.props.match.params.uuid } });

  getStorageName = () => 'otto_entities_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  loadOnMount = () => {}

  indexTable = () => this.paginationTable();

  getPageTitle = () => `Entity Group: ${this.props.match.params.name}`;

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Entity';

  getAddText = () => 'Create Entity';

  getWidthCenter = () => 8;

  getWidthLeft = () => 2;

  getWidthRight = () => 2;

  goToList = () => this.props.history.push('/entity_groups');

  smallTitle = () => (
    <Row>
      <Col md="8" className="text-left">
        <PageTitle title={this.getPageTitle()} subtitle={this.getPageSubtitle()} />
      </Col>
      <Col md="4" className="align-self-center text-right">
        <Button className="contracto__margin_btm_10" color="info" outline onClick={this.goToList}>
          Entity Group List
        </Button>
      </Col>
    </Row>
  );
});
