import { OptionsBaseServerApi } from '@westernmilling/eu_react_shared';

export default class LocationOptions extends OptionsBaseServerApi {
  getApiName = () => 'entities/locations';

  getApiParams = () => ({
    filtered: [
      { id: 'active', value: 'Yes' },
      { id: 'entity_uuid', value: this.props.entityUuid },
    ],
  });

  getDropdownLabelText = () => 'Location';

  getDropdownFieldName = () => this.props.fieldName || 'location_uuid';

  getLabelFieldName = () => this.props.valueName || 'location_with_reference';

  getFilterField = () => this.props.valueName || 'location_with_reference';
}
