import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/VolumeMeasurement';

export default withAuth(class VolumeMeasurementsListContainer extends CrudList {
  getColumns = () => this.sharedNameColumns(true);

  getFormTag = () => ItemForm;

  getListApi = () => 'credit/volume_measurements';

  getStorageName = () => 'otto_volume_measurements_list';

  getPageTitle = () => 'Volume Measurements';

  getAddGroups = () => ['credit_admin'];

  getEditGroups = () => ['credit_admin'];

  auditLogModelType = () => 'Credit::VolumeMeasurement';

  getAddText = () => 'Create Volume Measurement';

  canDownloadCsv = () => true;
});
