import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Button, Col, Collapse, Row,
} from 'reactstrap';
import {
  AddIcon, CaretToggle, CrudList, HtmlHelpers,
} from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/EntityLocations';
import ItemForm from 'components/Forms/EntityLocation';
import SystemModal from 'components/Tables/LocationSystems';

export default class EntityLocations extends CrudList {
  constructor(props) {
    super(props);
    this.state.open = false;
  }

  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/locations';

  getListParams = () => ({
    with_systems: 'Y',
    show_all: 'true',
    filtered: [{ id: 'entity_uuid', value: this.props.uuid }],
  });

  getStorageName = () => 'otto_entity_locations_list';

  getAddGroups = () => ['entities_admin'];

  getPageTitle = () => 'Locations';

  getAddText = () => 'Add Location';

  auditLogModelType = () => 'Entities::Location';

  selectItem = (id) => this.setState({ editForm: ItemForm, showModal: true, editItem: id });

  openModal = () => this.setState({ editForm: ItemForm, showModal: true });

  openSystemModal = (id) => this.setState({ editForm: SystemModal, showModal: true, editItem: id });

  canDownloadCsv = () => false;

  handleCloseModal = () => {
    this.setState({ showModal: false, editForm: null, editItem: -1 });
    this.refreshAfterUpdate();
  }

  getForm = () => {
    const TagName = this.state.editForm;
    const update = TagName === ItemForm ? this.updateData : null;
    return this.state.showModal ? (
      <TagName
        entity_uuid={this.props.item.uuid}
        entity_reference={this.props.item.reference}
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.editItem}
        showModal={this.state.showModal}
        closeModal={this.handleCloseModal}
        updateData={update}
      />
    ) : '';
  }

  getAddButtons = () => (
    this.isAddAdmin() ? (
      <Col md="8" xs="12" className="text-right">
        <AddIcon
          onClick={this.props.viewState.legalAction}
          addText="Update Legal Address"
          className="btn-sm otto__margin_right"
        />
        <AddIcon
          onClick={this.props.viewState.billToAction}
          addText="Update Bill To"
          className="btn-sm otto__margin_right"
        />
        <AddIcon
          onClick={this.props.viewState.remitToAction}
          addText="Update Remit To"
          className="btn-sm otto__margin_right"
        />
        <AddIcon
          onClick={this.openModal}
          addText={this.getAddText()}
          className="btn-sm"
        />
      </Col>
    ) : ''
  );

  getContent = () => (
    <div>
      <hr />
      <Row>
        <Col md="4" xs="12">
          <h4>
            Locations (
            {this.state.loading ? 'loading' : this.state.data.length}
            )
            &nbsp;&nbsp;&nbsp;
            <CaretToggle onClick={() => this.setState((prevState) => ({ open: !prevState.open }))} open={this.state.open} />
          </h4>
        </Col>
        { this.getAddButtons() }
      </Row>
      <Collapse isOpen={this.state.open}>
        { this.indexTable() }
      </Collapse>
      { this.itemForm() }
      { this.auditLogModal() }
    </div>
  )
}
