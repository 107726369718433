import React from 'react';
import {
  Button, Input, Modal, ModalBody, ModalHeader,
} from 'reactstrap';

import { HtmlHelpers } from '@westernmilling/eu_react_shared';

const ReviewNotesModal = function ReviewNotesModal(props) {
  return (
    <Modal size="lg" isOpen={props.showModal} toggle={props.closeModal}>
      <ModalHeader toggle={props.closeModal}>
        { `${props.options.header} for ${props.options.reviewDate} Review` }
      </ModalHeader>
      <ModalBody>
        { props.options.msg2 }
        { HtmlHelpers.isNotEmpty(props.options.msg2) ? <br /> : '' }
        { HtmlHelpers.isNotEmpty(props.options.msg2) ? <br /> : '' }
        { props.options.header === 'Financial Statement Info' ? '' : 'Notes:' }
        <pre>
          {props.options.msg}
        </pre>
      </ModalBody>
    </Modal>
  );
};

export default ReviewNotesModal;
