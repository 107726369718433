import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

import Systems from 'components/Options/SystemsActive';
import Languages from 'components/Options/Languages';

export default withAuth(withAlert()(class ContactForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.model_uuid ||= this.props.additionalFormProps.entity_uuid;
    this.state.item.model_type ||= 'Entities::Entity';
    this.state.item.phone_1_text ||= false;
    this.state.item.phone_2_text ||= false;
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  formType = () => 'Contact';

  getCrudType = () => 'entities/entity_contacts';

  successAlertMsg = () => this.successMsg('Contact');

  updateSystemData = (data) => this.setState((prevState) => {
    const newData = { ...prevState.item };
    newData.systems_hash = data;
    return { item: newData };
  });

  options = () => (
    {
      languages: {
        component: Languages,
        field: 'language_uuid',
        label: 'Language',
        currentValue: this.getItem().language_uuid,
        props: { isRequired: true },
      },
    }
  )

  modalBodyRows = () => (
    <div>
      { this.getTextFieldRow('name', 'Name') }
      { this.getTextFieldRow('position', 'Used For', false) }
      { this.getTextFieldRow('email_address', 'Email Address', false) }
      { this.getTextFieldRow('phone_1', 'Phone', false) }
      { this.getTextFieldRow('phone_2', 'Alternative Phone', false) }
      { this.getTextFieldRow('fax_number', 'Fax Number', false) }
      { this.dropdownField('languages') }
      { this.yesNoField('phone_1_text', 'Can Text Phone 1', false) }
      { this.yesNoField('phone_2_text', 'Can Text Phone 2', false) }
      { this.activeField() }
      <hr />
      <Systems values={this.getItem().systems_hash || {}} updateSystemData={this.updateSystemData} />
    </div>
  )
}));
