import React from 'react';
import {
  Button, Input, Modal, ModalBody, ModalHeader,
} from 'reactstrap';

import { HtmlHelpers } from '@westernmilling/eu_react_shared';

const CustomerModal = function CustomerModal(props) {
  return (
    <Modal size="lg" isOpen={props.showModal} toggle={props.closeModal}>
      <ModalHeader toggle={props.closeModal}>
        {props.options.header}
      </ModalHeader>
      <ModalBody>
        {props.options.header.includes('Collateral') ? 'Notes:' : ''}
        {props.options.header.includes('Collateral') ? (
          <pre>
            {props.options.msg}
          </pre>
        ) : props.options.msg}
      </ModalBody>
    </Modal>
  );
};

export default CustomerModal;
