import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'System',
    accessor: 'system',
    className: 'text-left',
  },
  {
    Header: 'Language',
    accessor: 'language',
    className: 'text-left',
  },
  {
    Header: 'Payment Term',
    accessor: 'terms_code',
    className: 'text-left',
  },
  {
    Header: 'Payment Method',
    accessor: 'payment_method',
    className: 'text-center',
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    className: 'text-center',
  },
  {
    Header: 'Carrier',
    accessor: 'carrier_text',
    className: 'text-center',
    width: 100,
  },
  ColumnHelpers.activeInactiveColumn(),
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
