import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class CountryRiskRatings extends OptionsBaseApi {
  getApiName = () => 'credit/country_risk_ratings';

  getApiParams = () => ({ order: 'name' });

  getDropdownLabelText = () => 'Country Risk Ratings';

  getDropdownFieldName = () => 'country_risk_rating_uuid';
}
