import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Customer',
    accessor: 'name_with_ref',
    className: 'text-left',
  },
  {
    Header: 'System',
    accessor: 'system_name',
    className: 'text-left',
    width: 300,
  },
  ColumnHelpers.activeInactiveColumn(),
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
