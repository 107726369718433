import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

export default withAuth(withAlert()(class CreditStatusForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
    this.state.item.job_can_change_status = this.state.item.job_can_change_status === undefined
      ? false
      : this.state.item.job_can_change_status;
  }

  formType = () => 'Credit Status';

  getCrudType = () => 'credit/credit_statuses';

  successAlertMsg = () => this.successMsg('Credit Status');

  modalBodyRows = () => (
    <div>
      { this.getTextFieldRow('name', 'Name') }
      { this.yesNoField('job_can_change_status', 'Automation Can Change Status') }
      { this.activeField() }
    </div>
  )
}));
