import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Entity extends OptionsBaseApi {
  getApiName = () => 'entities/entities';

  getDropdownLabelText = () => 'Entity';

  getApiParams = () => this.props.apiParams || [];

  getDropdownFieldName = () => this.props.fieldName || 'entity_uuid';

  getValueFieldName = () => this.props.valueField || 'uuid';
}
