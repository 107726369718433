import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudView } from '@westernmilling/eu_react_shared';

import ItemView from 'components/Forms/EntityView';
import ItemForm from 'components/Forms/Entity';
import BillToForm from 'components/Forms/BillTo';
import LegalAddressForm from 'components/Forms/LegalAddressForm';
import RemitToForm from 'components/Forms/RemitTo';
import ActionLinks from 'components/ActionMenus/EntityView';

export default withAuth(withAlert()(class EntityViewContainer extends CrudView {
  constructor(props) {
    super(props);
    this.state.section = this.getSection();
    this.state.editForm = ItemForm;
    this.state.legalAction = () => this.openUpdateModal(LegalAddressForm);
    this.state.billToAction = () => this.openUpdateModal(BillToForm);
    this.state.remitToAction = () => this.openUpdateModal(RemitToForm);
  }

  getSection = () => (
    {
      type: 'entities/entities',
      form: ItemView,
      title: 'View Entity',
      listRoute: '../entities',
      listLabel: 'Entity List',
      edit_admins: ['entities_admin'],
    }
  )

  editForm = () => this.state.editForm;

  editButton = () => '';

  additionalButtonsAfterList = () => (
    <span>
      <ActionLinks
        linkInfo={this.state.linkInfo || {}}
        uuid={this.getItem().uuid}
        editEvent={this.editEvent}
        editBillTo={this.state.billToAction}
        editRemitTo={this.state.remitToAction}
        editLegalAddress={this.state.legalAction}
      />
    </span>
  );

  openModal = () => this.setState({ editForm: ItemForm, showModal: true });

  openUpdateModal = (type) => this.setState({ editForm: type, showModal: true });

  handleCloseModal = () => this.setState({ showModal: false, editForm: null });

  billTo = () => (this.state.showBillToModal ? (
    <BillToForm
      data={this.state.data}
      editItem="0"
      showModal={this.state.showBillToModal}
      closeModal={this.handleCloseModal}
      updateData={this.updateData}
    />
  ) : '');

  legalAddress = () => (this.state.showLegalModal ? (
    <LegalAddressForm
      data={this.state.data}
      editItem="0"
      showModal={this.state.showLegalModal}
      closeModal={this.handleCloseModal}
      updateData={this.updateData}
    />
  ) : '');

  remitTo = () => (this.state.showRemitToModal ? (
    <RemitToForm
      data={this.state.data}
      editItem="0"
      showModal={this.state.showRemitToModal}
      closeModal={this.handleCloseModal}
      updateData={this.updateData}
    />
  ) : '');
}));
