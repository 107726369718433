import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Alert } from 'reactstrap';
import { CrudForm, Links } from '@westernmilling/eu_react_shared';

import CounterpartyTypes from 'components/Options/CounterpartyTypes';
import CreditRatings from 'components/Options/CreditRatings';
import EntityGroups from 'components/Options/EntityGroups';
import TaxLocations from 'components/Options/TaxLocations';

export default withAuth(withAlert()(class EntityForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.ten99_needed ||= false;
    this.state.item.employee_acct ||= false;
    this.state.item.nda ||= false;
    this.state.item.carrier_agency ||= false;
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
  }

  formType = () => 'Entity';

  getCrudType = () => 'entities/entities';

  successAlertMsg = () => this.successMsg('Entity');

  options = () => (
    {
      counterparty: {
        component: CounterpartyTypes,
        field: 'counterparty_type_uuid',
        label: 'Counterparty Type',
        props: { isRequired: true },
      },
      group: {
        component: EntityGroups,
        field: 'entity_group_uuid',
        label: 'Entity Group',
      },
      rating: {
        component: CreditRatings,
        field: 'credit_rating_uuid',
        label: 'Credit Rating',
      },
      taxLoc: {
        component: TaxLocations,
        field: 'tax_location',
        label: 'Tax Location',
        props: { isRequired: true },
      },
    }
  )

  showWarning = () => (
    !this.isNew() && (
      this.getItem().active.toString() === 'false' && this.props.data[this.props.editItem].active.toString() === 'true'
    )
  );

  getWarning = () => (this.showWarning() ? (
    <Alert color="warning" className="text-center">
      Inactivating this entity will inactivate all Contacts, Customers, Vendor Systems, and Locations
    </Alert>
  ) : '');

  handleSuccess = (data) => {
    window.location = Links.baseUrl('entities', `entity/view/${data.attributes.uuid}`);
  }

  modalBodyRows = () => (
    <div>
      {this.isNew() ? '' : this.getDisplayFieldRow('reference', 'Reference', this.getItem().reference)}
      {this.getDateFieldRow('business_start_date', 'Business Start Date', false)}
      {this.getTextFieldRow('name', 'Name')}
      {this.getTextFieldRow('nif', 'NIF', false)}
      {this.getTextFieldRow('cif', 'CIF', false)}
      {this.getTextFieldRow('vat', 'VAT', false)}
      {this.getTextFieldRow('eori', 'EORI', false)}
      {this.getTextFieldRow('website', 'Website', false)}
      {this.dropdownField('counterparty')}
      {this.dropdownField('taxLoc')}
      {this.dropdownField('rating')}
      {this.dropdownField('group')}
      {this.yesNoField('ten99_needed', '1099 Needed')}
      {this.yesNoField('employee_acct', 'Employee Account')}
      {this.yesNoField('broker', 'Broker')}
      {this.yesNoField('carrier_agency', 'Carrier Agency')}
      {this.yesNoField('nda', 'NDA')}
      {this.activeField()}
      {this.getWarning()}
    </div>
  )

  isTrueFalseInt = (field, label) => (
    this.getToggleRadioField({ label: 'Yes', value: '1' }, { label: 'No', value: '0' }, field, label)
  )
}));
