import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class CreditStatus extends OptionsBaseApi {
  getDropdownLabelText = () => 'Credit Status';

  getDropdownFieldName = () => 'credit_status';

  getApiName = () => 'credit/credit_statuses';

  getValueFieldName = () => 'code';
}
