import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Button, Col, Collapse, Row,
} from 'reactstrap';
import { CaretToggle, CrudList, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/EntityCustomers';
import ItemForm from 'components/Forms/EntityCustomer';
import CustomerModal from 'components/Modals/CustomerModal';

export default class EntityCustomers extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/customers';

  getListParams = () => ({
    show_all_status: 'true',
    filtered: [{ id: 'entity_uuid', value: this.props.uuid }],
  });

  getStorageName = () => 'otto_entity_customers_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Customers';

  getAddText = () => 'Add Customer';

  auditLogModelType = () => 'Entities::Customer';

  getTableClassSettings = () => 'otto__short_table';

  canDownloadCsv = () => false;

  hasBillTo = () => HtmlHelpers.isNotEmpty(this.props.item.bill_to_location_uuid);

  addBillToMessage = () => (
    <div style={{ paddingTop: '8px' }}>
      { this.hasBillTo() ? '' : 'You must add a Bill to Location before adding a Customer' }
    </div>
  );

  createButton = () => (
    <Button className="btn-sm" color="info" title="Add" outline onClick={this.handleOpenModal} disabled={!this.hasBillTo()}>
      {this.getAddText()}
    </Button>
  );

  updateData = () => {
    this.setState({
      showModal: false, showBulkModal: false, editItem: -1, loading: true,
    });
    this.refreshAfterUpdate();
    this.props.updateData();
  }

  getForm = () => {
    const TagName = this.getFormTag();
    return (
      <TagName
        entity_uuid={this.props.uuid}
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItem}
        showModal={this.state.showModal}
        closeModal={this.handleCloseModal}
        updateData={this.updateData}
        includes={this.state.includes}
      />
    );
  }

  showCreditTotals = (idx) => {
    const row = this.state.data[idx].customer_locations_limits;
    return HtmlHelpers.isNotEmpty(row.credit_limit);
  };

  getCustomerLocations = (idx) => (
    Object.values(this.state.includes['customer-locations'] || {})
      .filter((obj) => obj.customer_uuid === this.state.data[idx].uuid)
  );

  creditTotal = (idx) => {
    let creditTotal;
    let mtmTotal;
    let tenorMax;
    let volumeTotal;
    let insuranceTotal;

    if (HtmlHelpers.isNotEmpty(this.state.data)) {
      creditTotal = this.state.data[idx].customer_locations_limits.credit_limit || '';
      mtmTotal = this.state.data[idx].customer_locations_limits.mtm_limit || '';
      tenorMax = this.state.data[idx].customer_locations_limits.tenor_limit || '';
      insuranceTotal = this.state.data[idx].customer_locations_limits.credit_insurance_limit || '';
      volumeTotal = this.state.data[idx].customer_locations_limits.volume_limits.map((vol) => (
        <div>
          {vol}
        </div>
      ));
    }

    return (
      <div>
        {`Credit ${creditTotal}`}
        <br />
        {`MTM ${mtmTotal}`}
        <br />
        {`Tenor ${tenorMax}`}
        <br />
        {`Credit Ins. ${insuranceTotal}`}
        <br />
        {volumeTotal}
      </div>
    );
  }

  openNoteModal = (options) => this.setState({ showNoteModal: true, noteOptions: options });

  closeNoteModal = () => { this.setState({ showNoteModal: false, noteOptions: {} }); };

  noteModal = () => (
    this.state.showNoteModal ? (
      <CustomerModal
        closeModal={this.closeNoteModal}
        showModal={this.state.showNoteModal}
        options={this.state.noteOptions}
      />
    ) : ''
  );

  getContent = () => (
    <div>
      <hr />
      <Row>
        <Col md="6" xs="12">
          <h4>
            Customers (
            {this.state.loading ? 'loading' : this.state.data.length}
            )
            &nbsp;&nbsp;&nbsp;
            <CaretToggle onClick={() => this.setState((prevState) => ({ open: !prevState.open }))} open={this.state.open} />
          </h4>
        </Col>
        <Col md="4" className="text-right">
          { this.addBillToMessage() }
        </Col>
        <Col md="2" xs="12" className="text-right">
          { this.isAddAdmin() ? this.createButton() : '' }
        </Col>
      </Row>
      <Collapse isOpen={this.state.open}>
        { this.indexTable() }
      </Collapse>
      { this.itemForm() }
      { this.auditLogModal() }
      { this.noteModal() }
    </div>
  )
}
