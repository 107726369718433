import React from 'react';
import { HtmlHelpers, Links, TableFilters } from '@westernmilling/eu_react_shared';

const statusByFilter = (filter, onChange) => {
  const newOnChange = (value) => {
    let val = value;
    if (val === 'all') {
      val = '';
    }
    return onChange(val);
  };
  const options = { all: 'All', no: 'System', yes: 'User' };
  return TableFilters.BasicDropdownFilter(filter, newOnChange, options);
};

const Columns = (context) => [
  {
    id: 'entity__reference',
    Header: 'Reference',
    accessor: 'entity_reference',
    Cell: (row) => {
      const link = `${Links.baseUrl('orders', 'sales/customer/view')}${row.original.uuid}`;
      return <a href={link}>{row.original.entity_reference}</a>;
    },
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    id: 'user__name',
    Header: 'Salesperson',
    accessor: 'salesperson_name',
    className: 'text-left',
    width: 180,
  },
  {
    Header: 'Reason',
    accessor: 'credit_status_reason',
    className: 'text-left',
    width: 150,
  },
  {
    id: 'credit_status_changed_by_user',
    Header: 'Status By',
    accessor: 'credit_status_by',
    Filter: ({ filter, onChange }) => statusByFilter(filter, onChange),
    className: 'text-center',
    width: 95,
  },
  {
    id: 'credit_status_table__name',
    Header: 'Credit Status',
    accessor: 'credit_status_name',
    className: 'text-center',
    width: 90,
  },
  {
    id: 'credit_status_automated_table__name',
    Header: () => (
      <div>
        Automated
        <br />
        Status
      </div>
    ),
    accessor: 'credit_status_automated_name',
    className: 'text-center',
    csvHeader: 'Automated Status',
    width: 90,
  },
  {
    id: 'credit_limit',
    Header: 'Credit Limit',
    accessor: 'limit',
    sortMethod: (a, b) => Number((a || '-1').replace(/[$,]/g, '')) - Number((b || '-1').replace(/[$,]/g, '')),
    className: 'text-right',
    width: 120,
  },
  {
    Header: 'Total Balance',
    accessor: 'total_balance',
    sortMethod: (a, b) => Number((a || '-1').replace(/[$,]/g, '')) - Number((b || '-1').replace(/[$,]/g, '')),
    className: 'text-right',
    width: 120,
  },
  {
    id: 'agris_balance_cents',
    Header: 'AR Balance',
    accessor: 'ar_balance',
    sortMethod: (a, b) => Number((a || '-1').replace(/[$,]/g, '')) - Number((b || '-1').replace(/[$,]/g, '')),
    className: 'text-right',
    width: 120,
  },
  {
    id: 'shipped_balance_cents',
    Header: () => (
      <div>
        Est Shipped
        <br />
        Balance
      </div>
    ),
    accessor: 'shipped_balance',
    sortMethod: (a, b) => Number((a || '-1').replace(/[$,]/g, '')) - Number((b || '-1').replace(/[$,]/g, '')),
    className: 'text-right',
    csvHeader: 'Est Shipped Balance',
    width: 120,
  },
  {
    id: 'term__name',
    Header: 'Credit Terms',
    accessor: 'terms_name',
    className: 'text-center',
    width: 120,
  },
  {
    id: 'term__days_until_hold',
    Header: () => (
      <div>
        Days Until
        <br />
        Hold
      </div>
    ),
    accessor: 'days_until_hold',
    className: 'text-center',
    csvHeader: 'Days Until Hold',
    width: 90,
  },
  {
    id: 'agris_oldest_due_date',
    Header: 'Oldest Date**',
    accessor: 'agris_oldest_due_date_formatted',
    sortMethod: HtmlHelpers.dateSort,
    Filter: ({ filter, onChange }) => TableFilters.DateRangeTableFilter(filter, onChange),
    className: 'text-center',
  },
];

export default Columns;
