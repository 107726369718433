import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class HoldReasonOptions extends OptionsBaseApi {
  getApiName = () => 'credit/hold_reasons';

  getApiParams = () => ({ query: 'active=true' });

  getDropdownLabelText = () => 'Hold Reason';

  getDropdownFieldName = () => this.props.fieldName;
}
