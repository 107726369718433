import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm } from '@westernmilling/eu_react_shared';

import Entities from 'components/Options/Entities';

export default withAuth(withAlert()(class RelatedEntityForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
    this.state.item.entity_uuid = this.props.additionalFormProps.entityUuid;
  }

  formType = () => 'Related Entity';

  getCrudType = () => 'entities/related_entities';

  successAlertMsg = () => this.successMsg('Related Entity');

  options = () => (
    {
      entity: {
        component: Entities,
        field: 'related_entity_uuid',
        label: 'Entity',
        props: {
          apiParams: { show_all: 'true', sorted: JSON.stringify([{ id: 'name', desc: false }]) },
          used: this.props.additionalFormProps.usedUuids,
        },
      },
    }
  )

  entityField = () => (
    this.isNew() ? (this.dropdownField('entity')) : (
      this.getDisplayFieldRow('related_entity_name', 'Related Entity', this.getItem().related_entity_name)
    )
  )

  modalBodyRows = () => (
    <div>
      {this.entityField()}
      {this.activeField()}
      <br />
    </div>
  )
}));
