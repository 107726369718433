import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/CounterpartyType';

export default withAuth(class CounterpartyTypesContainer extends CrudList {
  getColumns = () => this.sharedNameColumns(true);

  getFormTag = () => ItemForm;

  getListApi = () => 'credit/counterparty_types';

  getStorageName = () => 'otto_counterparty_types_list';

  getAddGroups = () => ['credit_admin'];

  getEditGroups = () => ['credit_admin'];

  getPageTitle = () => 'Counterparty Types';

  getAddText = () => 'Create Counterparty Type';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Credit::CounterpartyType';
});
