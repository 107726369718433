import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import {
  AuditLogModal, CrudForm, DeleteIcon, HtmlHelpers,
} from '@westernmilling/eu_react_shared';

import Entities from 'components/Options/Entities';

export default withAuth(withAlert()(class TmsUserEdit extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
    this.state.item.provider = 'auth0';
    this.state.item.user_entities ||= [{}];
    if (HtmlHelpers.isEmpty(this.state.item.user_entities)) {
      this.state.item.user_entities.push({ active: true });
    }
  }

  formType = () => 'TMS User';

  getCrudType = () => 'admin/users';

  successAlertMsg = () => this.successMsg('User');

  options = (idx) => (
    {
      entity: {
        component: Entities,
        field: `entity_uuid_${idx}`,
        label: 'Entity',
        currentValue: this.getItem().user_entities[idx].entity_uuid,
        props: {
          isRequired: true,
          used: this.usedEntities(),
          apiParams: { show_all: 'true', sorted: JSON.stringify([{ id: 'name', desc: false }]) },
        },
        labelColumns: -1,
        fieldColumns: 12,
        disabled: this.disabledEntity(idx),
      },
    }
  );

  usedEntities = () => (
    HtmlHelpers.isEmpty(this.getItem().user_entities)
      ? []
      : this.getItem().user_entities.map((i) => i.entity_uuid).filter((entity) => entity)
  );

  deleteIcon = (idx) => (
    <DeleteIcon iconSize="1x" onClick={() => this.removeEntity(idx)} />
  );

  getEntities = () => (
    this.getItem().user_entities.map((row, idx) => this.getEntityRow(idx, row))
  );

  entityActive = (idx) => (
    [undefined, true, 'true'].includes((this.state.item.user_entities[idx] || {}).active) ? 'true' : 'false'
  );

  showActiveField = (idx) => (
    this.getToggleRadioField(
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
      `active_${idx}`,
      '',
      true,
      false,
      { value: this.entityActive(idx), labelColumns: -1, fieldColumns: 12 },
    )
  );

  auditLogModal = () => (
    this.state.auditLogModalOpen ? (
      <AuditLogModal
        auth={this.props.auth}
        showModal={this.state.auditLogModalOpen}
        closeModal={this.closeAuditLogModal}
        modelType="Admin::UserEntity"
        modelUuid={this.state.auditLogObj.uuid}
      />
    ) : ''
  )

  openAuditLogModal = (idx) => this.setState({ auditLogModalOpen: true, auditLogObj: this.getItem().user_entities[idx] });

  closeAuditLogModal = () => this.setState({ auditLogModalOpen: false, auditLogObj: null })

  auditLogButton = (idx) => this.getButton('Audit Logs', () => this.openAuditLogModal(idx));

  disabledEntity = (idx) => (
    HtmlHelpers.isNotEmpty(this.state.item.user_entities[idx].created_at)
  );

  getEntityRow = (idx, row) => (
    <Row key={`entity_field_${idx + 1}`}>
      <Col md="6">
        {this.dropdownField('entity', idx)}
      </Col>
      <Col md="4" style={{ paddingTop: '10px' }}>
        { this.disabledEntity(idx) ? this.showActiveField(idx) : this.deleteIcon(idx) }
      </Col>
      <Col md="2" style={{ paddingTop: '4px' }}>
        { this.disabledEntity(idx) ? this.auditLogButton(idx) : '' }
      </Col>
    </Row>
  );

  entityHeaderDisplay = () => (
    <Row>
      <Col md="6" className="text-center">
        <b>Name</b>
      </Col>
      <Col md="3" className="text-center">
        <b>Active</b>
      </Col>
    </Row>
  );

  addEntity = () => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.user_entities.push({ active: true });
    return { item };
  });

  removeEntity = (idx) => this.setState((prevState) => {
    const item = { ...prevState.item };
    item.user_entities.splice(idx, 1);
    delete item[`entity_uuid_${idx}`];
    if (item.user_entities.length < 1) {
      item.user_entities.push({});
    }
    return { item };
  });

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const value = HtmlHelpers.dig([event.target, 'value', 'value']) || event.target.value;
    const last = name.lastIndexOf('_');
    const index = name.substring(last + 1);

    if (name.includes('entity_uuid_')) {
      newData.user_entities[index].entity_uuid = newData[name];
    }

    if (name.includes('active_')) {
      newData.user_entities[index].active = newData[name];
    }

    this.setVariables({ item: newData });
  }

  modalBodyRows = () => (
    <div>
      {this.getTextFieldRow('name', 'Name')}
      {this.getTextFieldRow('email_address', 'Email')}
      {this.activeField()}
      <hr />
      <h5>Entities</h5>
      {this.entityHeaderDisplay()}
      <br />
      {this.getEntities()}
      <Col md="12" className="text-right">
        {this.getButton('Add Another Entity', this.addEntity) }
      </Col>
      {this.auditLogModal()}
    </div>
  );
}));
