import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import {
  Api, CrudForm, DeleteIcon, HtmlHelpers,
} from '@westernmilling/eu_react_shared';

import CreditLocations from 'components/Options/CreditLocations';
import CreditSectors from 'components/Options/CreditSectors';
import CreditStatus from 'components/Options/CreditStatus';
import Currencies from 'components/Options/Currencies';
import GuarantyTypes from 'components/Options/GuarantyTypes';
import HoldReason from 'components/Options/HoldReason';
import Languages from 'components/Options/Languages';
import Systems from 'components/Options/Systems';
import Terms from 'components/Options/Terms';
import VolumeMeasurements from 'components/Options/VolumeMeasurements';

export default withAuth(withAlert()(class EntityCustomerForm extends CrudForm {
  constructor(props) {
    super(props);

    this.state.formKey = 1;
    this.state.systemName = HtmlHelpers.isEmpty(props.data[props.editItem]) ? '' : props.data[props.editItem].system_name;
    this.state.item.entity_uuid ||= this.props.entity_uuid;
    this.state.item.credit_app ||= false;
    this.state.item.active = this.state.item.active === undefined ? true : this.state.item.active;
    this.state.item.guaranty_type ||= 'None';

    const locs = this.getIncludedLocations();
    this.state.item.locations = HtmlHelpers.isNotEmpty(locs) ? locs : [{ active: true }];
    locs.forEach((obj, idx) => {
      this.state.item[`limits_volume_measurement_uuid_${idx}`] = obj.volume_measurement_uuid;
      this.state.item[`limits_dim_location_name_${idx}`] = obj.dim_location_name;
    });
  }

  componentDidMount = async () => {
    await this.getTermsOptions(this.getItem().system_uuid);
  }

  getIncludedLocations = () => (
    Object.values(this.props.includes['customer-locations'] || {})
      .filter((obj) => obj.customer_uuid === this.state.item.uuid)
  );

  formType = () => 'Customer';

  getCrudType = () => 'entities/customers';

  successAlertMsg = () => this.successMsg('Customer');

  getCustomerLocations = () => this.getItem().locations || [];

  getModalSize = () => 'xl';

  getModalClassName = () => 'otto__modal-xl-wide';

  options = (num) => (
    {
      creditLocations: {
        component: CreditLocations,
        field: `limits_dim_location_name_${num}`,
        label: '',
        labelColumns: -1,
        fieldColumns: 12,
        currentValue: (this.getCustomerLocations()[num] || {}).dim_location_name,
        props: { systemName: this.state.systemName, key: this.state.formKey, isRequired: true },
      },
      creditSectors: {
        component: CreditSectors,
        field: 'credit_sector_uuid',
        label: 'Sector/ Industry',
        props: { isRequired: true },
      },
      creditStatus: {
        component: CreditStatus,
        field: 'credit_status',
        label: 'Credit Status',
        props: { isRequired: true },
      },
      currency: {
        component: Currencies,
        field: 'currency',
        label: 'currency',
        props: { isRequired: true },
      },
      guarantyType: {
        component: GuarantyTypes,
        field: 'guaranty_type',
        label: 'Guaranty Type',
        props: { isRequired: true },
      },
      holdReason: {
        component: HoldReason,
        field: 'credit_status_reason',
        label: 'Hold Reason',
        props: { valueField: 'name', isRequired: true },
      },
      languages: {
        component: Languages,
        field: 'language_uuid',
        label: 'Language',
        props: { isRequired: true },
      },
      measurements: {
        component: VolumeMeasurements,
        field: `limits_volume_measurement_uuid_${num}`,
        label: '',
        labelColumns: -1,
        fieldColumns: 12,
        currentValue: (this.getCustomerLocations()[num] || {}).volume_measurement_uuid,
      },
      system: {
        component: Systems,
        field: 'system_uuid',
        label: 'System',
      },
      term: {
        component: Terms,
        field: 'terms_uuid',
        label: 'Term',
        data: this.state.termOptions,
        value: this.getItem().terms_uuid,
      },
    }
  );

  locationActive = (idx) => (
    [undefined, true, 'true'].includes((this.getCustomerLocations()[idx] || {}).active) ? 'true' : 'false'
  );

  limitActiveField = (idx) => (
    this.getToggleRadioField(
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
      `limits_active_${idx}`,
      '',
      true,
      false,
      { value: this.locationActive(idx), labelColumns: -1, fieldColumns: 12 },
    )
  );

  limitField = (field, idx, required = false) => (
    this.getNumericFieldRow(
      `limits_${field}_${idx}`,
      null,
      required,
      { value: (this.getCustomerLocations()[idx] || {})[field], labelColumns: -1, fieldColumns: 12 },
    )
  );

  getLabelCol = (text, num = 1) => (
    <Col md={num} className="otto__label">
      {text}
    </Col>
  );

  customerLocationHeader = () => (
    <Row key="customerLocationHeader" className="text-center">
      {this.getLabelCol('Location', 3)}
      {this.getLabelCol('Credit')}
      {this.getLabelCol('MTM')}
      {this.getLabelCol('Tenor')}
      {this.getLabelCol('Volume')}
      {this.getLabelCol('Measurement', 2)}
      {this.getLabelCol('Credit Ins.')}
      <Col md="1" className="otto__label text-center">
        Active
      </Col>
    </Row>
  );

  getFieldCol = (text, num = 1) => (
    <Col md={num}>
      {text}
    </Col>
  );

  customerLocationRow = (row, idx) => (
    <Row key={`cust_row_${idx}`}>
      {this.getFieldCol(this.dropdownField('creditLocations', idx), 3)}
      {this.getFieldCol(this.limitField('credit_limit', idx, true))}
      {this.getFieldCol(this.limitField('mtm_limit', idx))}
      {this.getFieldCol(this.limitField('tenor_limit', idx))}
      {this.getFieldCol(this.limitField('volume_limit', idx))}
      {this.getFieldCol(this.dropdownField('measurements', idx), 2)}
      {this.getFieldCol(this.limitField('credit_insurance_limit', idx))}
      <Col md="2">
        <Row>
          {this.getFieldCol(this.limitActiveField(idx), 8)}
          <Col md="4" className="text-left otto__no_padding">
            {this.deleteIcon(idx)}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  customerLocations = () => {
    const current = this.getCustomerLocations() || [];
    const content = current.map((row, idx) => (this.customerLocationRow(row, idx)));
    if (HtmlHelpers.isEmpty(content)) {
      content.push(this.customerLocationRow({ active: true }, 0));
    }
    content.push(this.addMore());
    return content;
  }

  locationLimitDisplay = () => (
    <div>
      <h5>Location Limits</h5>
      {this.customerLocationHeader()}
      <br />
      {this.customerLocations()}
    </div>
  );

  guaranteeNameField = () => (
    HtmlHelpers.isNotEmpty(this.getItem().guaranty_type) && this.getItem().guaranty_type !== 'None' ? (
      this.getTextFieldRow('guaranty_name', 'Guaranty Name')
    ) : ''
  );

  modalBodyRows = () => (
    <div>
      {this.system()}
      {this.dropdownField('languages')}
      {this.dropdownField('term')}
      {this.dropdownField('creditStatus')}
      {this.getItem().credit_status === 'hold' ? this.dropdownField('holdReason') : ''}
      {this.dropdownField('currency')}
      {this.dropdownField('creditSectors')}
      {this.dropdownField('guarantyType')}
      {this.guaranteeNameField()}
      {this.getTextareaRow('collateral', 'Collateral')}
      {this.yesNoField('credit_app', 'Credit App')}
      {this.activeField()}
      <hr />
      {HtmlHelpers.isNotEmpty(this.getItem().system_uuid) ? this.locationLimitDisplay() : ''}
    </div>
  )

  addMore = () => (
    <Row key="addMore" className="text-right">
      <Col md="12">
        {this.getButton('Add Another Location', this.addAnotherRow)}
      </Col>
      <br />
      <br />
    </Row>
  )

  removeLoc = (idx) => this.setState((prevState) => {
    const item = prevState.item;
    delete (item.locations || [])[idx];
    return { item };
  });

  addAnotherRow = () => this.setState((prevState) => {
    const item = prevState.item;
    if (HtmlHelpers.isEmpty(item.locations)) {
      item.locations = [{ active: true }, { active: true }];
    } else {
      item.locations.push({ active: true });
    }
    return { item };
  });

  deleteIcon = (idx) => (
    HtmlHelpers.isNotEmpty((this.getCustomerLocations()[idx] || {}).uuid) ? '' : (
      <DeleteIcon iconSize="1x" onClick={() => this.removeLoc(idx)} className="wm__no_padding" />
    )
  );

  system = () => (
    this.isNew() ? this.dropdownField('system') : (
      this.getDisplayFieldRow('system_name', 'System', this.getItem().system_name)
    )
  );

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const value = HtmlHelpers.dig([event.target, 'value', 'value']) || event.target.value;

    if (name.includes('limits_')) {
      const locs = newData.locations || [];
      if (name.includes('dim_location_name') && HtmlHelpers.isNotEmpty(value)) {
        const last = name.lastIndexOf('_');
        const index = name.substring(last + 1);
        newData.locations = this.setLimits(locs, name, value);
        newData.locations = this.setLimits(newData.locations, `limits_dim_location_code_${index}`, event.target.value.code);
      } else {
        newData.locations = this.setLimits(locs, name, value);
      }
    }

    if (name === 'system_uuid' && HtmlHelpers.isNotEmpty(value)) {
      this.setState((prevState) => ({ formKey: prevState.formKey + 1, systemName: event.target.value.label }));
      newData.locations = [{ active: true }];
      this.getTermsOptions(newData.system_uuid);
    }

    if (name === 'guaranty_type' && value === 'None') {
      newData.guaranty_name = null;
    }

    this.setVariables({ item: newData });
  };

  setLimits = (locations, name, value) => {
    const locs = Object.assign([], locations);
    const first = name.indexOf('_') + 1;
    const last = name.lastIndexOf('_');
    const field = name.substring(first, last);
    const index = name.substring(last + 1);

    const item = locs[index] || {};
    item[field] = value;
    locs[index] = item;

    if (HtmlHelpers.isEmpty(value) && !Object.keys(locs[index]).includes('uuid')) {
      delete item[field];
      if (name.includes('dim_location_name')) {
        delete item.dim_location_code;
      }
    }

    return locs;
  }

  getTermsOptions = async (systemUuid) => {
    const termOptions = [];

    if (HtmlHelpers.isNotEmpty(systemUuid)) {
      await Api.crudList(this.props.auth, 'credit/terms', {
        filtered: JSON.stringify([{ id: 'system_uuid', value: systemUuid }]),
        show_all: 'true',
      }).then((response) => {
        if (response.data !== undefined) {
          response.data.forEach((d) => {
            termOptions.push({ label: d.attributes.code, value: d.attributes.uuid });
          });
        }
      });
    }

    this.setState({ termOptions });
  };

  additionalValidations = () => {
    const errors = [];
    if (this.getItem().credit_status === 'hold' && HtmlHelpers.isEmpty(this.getItem().credit_status_reason)) {
      errors.push('Hold Reason is required');
    }
    this.setState({ errors });
    return errors;
  };
}));
