import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Short Code',
    accessor: 'name',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Description',
    accessor: 'description',
    className: 'text-left',
  },
  ColumnHelpers.activeColumn(),
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
