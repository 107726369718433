import React from 'react';
import { ColumnButton, ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    Header: 'Used For',
    accessor: 'position',
    className: 'text-left',
  },
  {
    Header: 'Email Address',
    accessor: 'email_address',
    className: 'text-left',
  },
  {
    Header: 'Phone',
    accessor: 'phone_1',
    className: 'text-left',
    width: 120,
  },
  {
    Header: 'Alt Phone',
    accessor: 'phone_2',
    className: 'text-left',
    width: 120,
  },
  {
    Header: 'Fax Number',
    accessor: 'fax_number',
    className: 'text-left',
    width: 120,
  },
  {
    id: 'systems',
    Header: 'Systems',
    accessor: (d) => d.active_system_names?.join(', '),
    Cell: (row) => (row.original.active_system_names || []).map((sn, idx) => (
      <div key={`sys_${idx + 1}`}>
        {sn}
      </div>
    )),
    className: 'text-left',
    width: 200,
  },
  {
    Header: 'Language',
    accessor: 'language_name',
    className: 'text-center',
    width: 120,
  },
  {
    id: 'notifications',
    Header: 'Notifications',
    accessor: (d) => d.active_notification_names?.join(', '),
    Cell: (row) => (row.original.active_notification_names || []).map((sn, idx) => (
      <div key={`sys_${idx + 1}`}>
        {sn}
      </div>
    )),
    className: 'text-left',
    width: 200,
  },
  ColumnHelpers.activeInactiveColumn(),
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.editColumn(context, {
    width: 120,
    additional: (row) => (
      ColumnHelpers.additonalButton(
        'Notifications',
        (() => context.handleOpenModal(row.index, 'Notification')),
      )
    ),
  }),
];

export default Columns;
