import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Entities';
import ItemForm from 'components/Forms/Entity';

export default withAuth(class EntityListContainer extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/entities';

  getListParams = () => ({ currency_all: 'Y' })

  getStorageName = () => 'otto_entities_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Entities';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Entities::Entity';

  getAddText = () => 'Create Entity';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;
});
