import React from 'react';
import { ColumnButton, ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context, onEntity = false) => [
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    Header: 'Entities',
    accessor: 'entity_names',
    className: 'text-left',
    show: !onEntity,
  },
  {
    Header: 'Email',
    accessor: 'email_address',
    className: 'text-left',
  },
  ColumnHelpers.activeInactiveColumn(),
  ColumnHelpers.editColumn(context, { show: !onEntity, modalName: 'TmsEdit' }),
];

export default Columns;
