import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/Currency';
import Columns from 'helpers/columns/Currencies';

export default withAuth(class CurrenciesContainer extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/currencies';

  getStorageName = () => 'otto_currencies_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Currencies';

  getAddText = () => 'Create Currency';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Entities::Currency';
});
