import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class Notifications extends OptionsBaseApi {
  getApiName = () => 'admin/notifications';

  getApiParams = () => ({ order: 'name' });

  getDropdownLabelText = () => 'Notification';

  getDropdownFieldName = () => 'notification_uuid';
}
