import { Api, OptionsBaseServerApi } from '@westernmilling/eu_react_shared';

export default class Customers extends OptionsBaseServerApi {
  getApiName = () => 'entities/customers';

  getDropdownLabelText = () => 'Customers';

  getDropdownFieldName = () => this.props.fieldName || 'customer_uuid';

  getLabelFieldName = () => 'name_ref_with_system';

  getFilterField = () => 'name_with_ref';
}
