import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Name',
    accessor: 'name',
    className: 'text-left',
  },
  {
    Header: 'Code',
    accessor: 'code',
    className: 'text-left',
  },
  {
    Header: 'Automation Can Change Status',
    accessor: 'can_change_status',
    className: 'text-center',
  },
  ColumnHelpers.activeInactiveColumn(),
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
