import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Locations from 'components/Options/EntityLocation';

export default withAuth(withAlert()(class BillToForm extends CrudForm {
  formType = () => 'Bill To';

  getCrudType = () => 'entities/entities';

  successAlertMsg = () => this.successMsg('Bill to');

  options = () => (
    {
      location: {
        component: Locations,
        field: 'bill_to_location_uuid',
        label: 'Bill To',
        props: { entityUuid: this.props.data[0].uuid },
        defaultData: this.defaultData(),
      },
    }
  );

  defaultData = () => {
    const entity = this.props.data[0];
    let data = null;
    if (HtmlHelpers.isNotEmpty(entity.bill_to_name)) {
      data = { text: entity.bill_to_name, id: entity.bill_to_location_uuid };
    }
    return data;
  }

  modalBodyRows = () => (
    <div>
      {this.dropdownField('location')}
    </div>
  )
}));
