import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ColumnHelpers, HtmlHelpers } from '@westernmilling/eu_react_shared';

const noteDisplay = (context, options, key = 1, style = {}) => (
  <div key={`${options.text}_${key}`}>
    <Button
      key={key}
      style={style}
      className="otto__no_padding"
      color="link"
      onClick={() => (context.openNoteModal(options))}
    >
      {options.text}
    </Button>
    <br />
  </div>
);

const getRowWithValue = (label, value) => (
  <Row>
    {HtmlHelpers.getWmLabel({ labelColumns: 3, text: label })}
    <Col md={5}>
      {value}
    </Col>
  </Row>
);

const Columns = (context) => [
  {
    Header: 'System',
    accessor: 'system_name',
    className: 'text-left',
  },
  {
    Header: 'Language',
    accessor: 'language',
    className: 'text-left',
  },
  {
    Header: 'Locations',
    accessor: 'customer__locations',
    Cell: (row) => {
      const display = context.getCustomerLocations(row.index).map((loc) => {
        const style = loc.active === false ? { textDecoration: 'line-through' } : {};
        const header = 'Location Limits';
        const text = loc.name_with_code;
        const key = `loc_${loc.id}`;
        const currency = row.original.currency || 'USD';
        const msg = (
          <Row>
            <Col md={12} className="text-left">
              {getRowWithValue('System', row.original.system_name)}
              {getRowWithValue('Location', loc.name_with_code)}
              {getRowWithValue('Active', loc.active_text)}
              {getRowWithValue('Credit Limit', loc.credit_limit_formatted)}
              {getRowWithValue('MTM Limit', loc.mtm_limit_formatted)}
              {getRowWithValue('Tenor Limit', loc.tenor_limit || 0)}
              {getRowWithValue('Credit Ins. Limit', loc.credit_insurance_limit_formatted)}
              {HtmlHelpers.isEmpty(loc.volume_limit)
                ? getRowWithValue('Volume Limit', 0)
                : getRowWithValue('Volume Limit', `${loc.volume_limit} ${loc.volume_measurement}`)}
            </Col>
          </Row>
        );

        return noteDisplay(context, { header, msg, text }, key, style);
      });
      return display;
    },
    className: 'text-left',
    filterable: false,
    sortable: false,
    width: 275,
  },
  {
    Header: 'Limits',
    accessor: 'credit_limit',
    Cell: (row) => (context.showCreditTotals(row.index) ? context.creditTotal(row.index) : ''),
    className: 'text-right',
    filterable: false,
    sortable: false,
    show: context.isAddAdmin(),
    width: 250,
  },
  {
    Header: 'Terms',
    accessor: 'terms_code',
    className: 'text-left',
    show: context.isAddAdmin(),
  },
  {
    id: 'credit_status_table__name',
    Header: 'Credit Status',
    accessor: 'credit_status_name',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Credit App',
    accessor: 'credit_app_text',
    className: 'text-center',
    width: 100,
    show: context.isAddAdmin(),
  },
  {
    Header: (
      <div>
        Sector/
        <br />
        Industry
      </div>
    ),
    accessor: 'credit_sector',
    className: 'text-center',
    width: 100,
  },
  {
    Header: 'Guaranty',
    accessor: 'guaranty_type',
    Cell: (row) => (
      HtmlHelpers.isNotEmpty(row.original.guaranty_type)
        ? (
          <div>
            {row.original.guaranty_type}
            <br />
            {row.original.guaranty_name}
          </div>
        )
        : ''
    ),
    className: 'text-center',
  },
  {
    Header: 'Collateral',
    accessor: 'collateral',
    Cell: (row) => {
      let display = '';
      const header = `Collateral for ${row.original.system_name}`;
      const msg = row.original.collateral;
      const text = 'Yes';
      if (HtmlHelpers.isNotEmpty(row.original.collateral)) {
        display = noteDisplay(context, { header, msg, text });
      }
      return display;
    },
    className: 'text-center',
    filterable: false,
    sortable: false,
    width: 70,
  },
  {
    Header: 'NSF',
    accessor: 'nsf_count',
    className: 'text-center',
    width: 90,
  },
  ColumnHelpers.activeInactiveColumn(),
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
