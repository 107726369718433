import React from 'react';
import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Agris Code',
    accessor: 'agris_code',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Name',
    accessor: 'name_translations',
    Cell: (row) => (
      row.original.name_translations.map((obj) => (
        <div key={`term_translation_${obj.id}`}>
          {`${obj.language}: ${obj.translation}`}
        </div>
      ))
    ),
    className: 'text-left',
    csvField: (d) => d.name_translations.map((obj) => (`${obj.language}: ${obj.translation}`)).join(', '),
  },
  {
    Header: 'Short Name',
    accessor: 'code',
    className: 'text-left',
    width: 300,
  },
  {
    Header: 'Days Until Hold',
    accessor: 'days_until_hold',
    className: 'text-center',
    width: 120,
  },
  {
    id: 'system__name',
    Header: 'System',
    accessor: 'system_name',
    className: 'text-left',
    width: 200,
  },
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
