import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Col, Row } from 'reactstrap';
import {
  AuditLogModal, CrudForm, HtmlHelpers, WmAuth,
} from '@westernmilling/eu_react_shared';

import EntityAttachments from 'components/Tables/EntityAttachments';
import EntityContacts from 'components/Tables/EntityContacts';
import EntityCustomers from 'components/Tables/EntityCustomers';
import EntityLocations from 'components/Tables/EntityLocations';
import EntityNotes from 'components/Tables/EntityNotes';
import EntityReferences from 'components/Tables/EntityReferences';
import EntityVendors from 'components/Tables/EntityVendors';
import EntityReviews from 'components/Tables/EntityReviews';
import EntityGroupView from 'components/Forms/EntityGroupView';
import RelatedEntities from 'components/Tables/RelatedEntities';
import TmsUsers from 'components/Tables/TmsUsers';

// TODO: Entity Groups
// {HtmlHelpers.isNotEmpty(this.getItem().entity_group) ? this.tableSection(EntityGroupView, 'view') : ''}

export default withAuth(withAlert()(class EntityViewForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.auditLog = { modalOpen: false, uuid: null, type: null };
    this.state.formKey = 1;
  }

  isAdmin = () => WmAuth.isEditAdmin(this.props.thisState, this.props.thisState.section.edit_admins);

  formType = () => 'Entity';

  getCrudType = () => 'entities/entities';

  attachments = () => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <EntityAttachments
      auth={this.props.auth}
      alert={this.props.alert}
      modelType="Entities::Entity"
      modelUuid={this.getItem().uuid}
      isAddAdmin
    />
  ));

  tableSection = (TagName, type = '') => (HtmlHelpers.isEmpty(this.getItem().uuid) ? '' : (
    <div>
      <TagName
        key={[EntityGroupView, EntityContacts].includes(TagName) ? this.state.formKey : 1}
        auth={this.props.auth}
        admin={this.isAdmin()}
        uuid={this.getItem().uuid}
        item={this.getItem()}
        type={type}
        viewState={this.props.thisState}
        updateData={() => this.setState((prevState) => ({ formKey: prevState.formKey + 1 }))}
      />
    </div>
  ));

  referenceDisplay = () => (
    this.getItem().references.map((ref) => (
      <div key={`reference_${ref}`}>
        {ref}
      </div>
    ))
  );

  modalBodyRows = () => (
    <div>
      <Row>
        <Col md={4} sm={12}>
          {this.getRowWithValue('Reference', this.referenceDisplay())}
          {this.getRow('name', 'Name')}
          {this.getRow('tax_location', 'Tax Location')}
          {this.getRow('counterparty_type', 'Counterparty Type')}
          {this.getRow('broker_text', 'Broker')}
          {this.getRow('carrier_agency_text', 'Carrier Agency')}
          {this.getRow('website', 'Website')}
          {this.getRow('business_start_date', 'Business Start Date')}
        </Col>
        <Col md={4} sm={12}>
          <pre style={{ overflow: 'hidden' }}>
            {this.getRow('legal_address', 'Legal Address')}
          </pre>
          <pre style={{ overflow: 'hidden' }}>
            {this.getRow('bill_to', 'Bill To')}
          </pre>
          {this.getRow('payee_name', 'Payee Name')}
          <pre style={{ overflow: 'hidden' }}>
            {this.getRow('remit_to', 'Remit To')}
          </pre>
        </Col>
        <Col md={4} sm={12}>
          {this.activeInactiveDisplay('Active', this.getItem().active_inactive_text)}
          {this.getRow('credit_rating', 'Credit Rating')}
          {this.getRow('employee_acct_text', 'Employee Account')}
          {this.getRow('ten99_needed_text', '1099 Needed')}
          {this.getRow('nif', 'NIF')}
          {this.getRow('cif', 'CIF')}
          {this.getRow('vat', 'VAT')}
          {this.getRow('eori', 'EORI')}
          {this.getRow('nda_text', 'NDA')}
        </Col>
      </Row>

      {this.tableSection(RelatedEntities)}
      {this.tableSection(EntityReviews)}
      {this.tableSection(EntityReferences)}
      {this.tableSection(EntityCustomers)}
      <hr />
      {this.tableSection(EntityContacts)}
      <hr />
      {this.tableSection(TmsUsers)}
      {this.tableSection(EntityVendors)}
      {this.tableSection(EntityLocations)}
      <EntityNotes auth={this.props.auth} uuid={this.getItem().uuid} />
      {this.attachments()}
    </div>
  )
}));
