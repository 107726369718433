import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/PaymentMethod';

export default withAuth(class PaymentMethodsContainer extends CrudList {
  getColumns = () => this.sharedNameColumns();

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/payment_methods';

  getStorageName = () => 'otto_payment_methods_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Payment Methods';

  getAddText = () => 'Create Payment Method';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Entities::PaymentMethod';
});
