import React from 'react';
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { ColumnHelpers, HtmlHelpers } from '@westernmilling/eu_react_shared';

const financialPopover = (date, key, text) => (
  HtmlHelpers.isNotEmpty(date) ? (
    <div>
      <Button className="otto__no_padding" color="link" id={`financial_${key}`} type="button">
        <span>
          {date}
        </span>
      </Button>
      <UncontrolledPopover target={`financial_${key}`} placement="bottom" trigger="legacy">
        <PopoverBody><pre>{text}</pre></PopoverBody>
      </UncontrolledPopover>
    </div>
  ) : 'No Financial'
);

const noteDisplay = (context, options) => (
  <Button className="otto__no_padding" color="link" onClick={() => (context.openNoteModal(options))}>
    {options.text}
  </Button>
);

const Columns = (context) => [
  {
    Header: 'Review Date',
    accessor: 'review_date',
    Cell: (row) => {
      const reviewDate = row.original.review_date;
      const header = 'Review Notes';
      const msg = row.original.review_notes;
      const text = reviewDate;
      return noteDisplay(context, {
        reviewDate, header, msg, text,
      });
    },
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Reviewed By',
    accessor: 'reviewed_by',
    className: 'text-left',
  },
  {
    Header: 'Next Review',
    accessor: 'next_review_date',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Current Ratio',
    accessor: 'current_ratio',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Tangible Net Worth',
    accessor: 'tangible_net_worth_formatted',
    className: 'text-right',
  },
  {
    Header: 'Employee Count',
    accessor: 'employee_count',
    className: 'text-right',
  },
  {
    Header: 'Financial Statement',
    accessor: 'financial_statement_date',
    Cell: (row) => {
      let display = 'No Financials';
      const reviewDate = row.original.review_date;
      const header = 'Financial Statement Info';
      const msg = `Entity Name on Statement: ${row.original.entity_name_financials}`;
      const msg2 = `Statement Date: ${row.original.financial_statement_date}`;
      const text = row.original.financial_statement_date;
      if (HtmlHelpers.isNotEmpty(row.original.financial_statement_date)) {
        display = noteDisplay(context, {
          reviewDate, header, msg, msg2, text,
        });
      }
      return display;
    },
    className: 'text-center',
  },
  {
    Header: 'Country Risk Rating',
    accessor: 'country_risk_rating',
    className: 'text-center',
  },
  {
    Header: 'EU Last Checked',
    accessor: 'ofac_last_checked',
    Cell: (row) => {
      const reviewDate = row.original.review_date;
      const header = 'EU Info';
      const msg = row.original.ofac_notes;
      const msg2 = `Last Checked Date: ${row.original.ofac_last_checked}`;
      const text = row.original.ofac_last_checked;
      return noteDisplay(context, {
        reviewDate, header, msg, msg2, text,
      });
    },
    className: 'text-center',
  },
  {
    Header: 'Credit Ins Expiration',
    accessor: 'insurance_expiration_date',
    Cell: (row) => {
      const reviewDate = row.original.review_date;
      const header = 'Credit Insurance Info';
      const msg = row.original.insurance_notes;
      const msg2 = `Expiration Date: ${row.original.insurance_expiration_date}`;
      const text = row.original.insurance_expiration_date;
      return noteDisplay(context, {
        reviewDate, header, msg, msg2, text,
      });
    },
    className: 'text-center',
  },
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
