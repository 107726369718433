import React from 'react';
import { Api, OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class CreditLocations extends OptionsBaseApi {
  getApiName = () => 'data_warehouse/dimlocations';

  getApiParams = () => ({ query: `left(bklocation, 1) = '2' and systemname = '${this.props.systemName}'` });

  getDropdownLabelText = () => 'Credit Locations';

  getDropdownFieldName = () => this.props.fieldName || 'dim_location_name';

  getLabelFieldName = (attr) => `${attr.bklocation} - ${attr.locationdescription}`;

  getValueFieldName = () => 'locationdescription';

  getOptions = async (auth) => {
    let options = [];
    if (!this.props.data) {
      await Api.crudList(auth, this.getApiName(), this.getApiParams(), this.getApiVersion())
        .then((response) => {
          if (response.data !== undefined) {
            const optionList = response.data.map((d) => (
              {
                label: this.getLabelFieldName(d.attributes),
                value: d.attributes[this.getValueFieldName()].trim(),
                code: d.attributes.bklocation,
              }
            ));
            options = this.uniqueArray(optionList);
          }
        });
    }

    this.setState({ items: options });
  }
}
