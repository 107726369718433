import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import ItemForm from 'components/Forms/CreditSector';

export default withAuth(class CreditSectorsContainer extends CrudList {
  auditLogModelType = () => 'Credit::CreditSector';

  getColumns = () => this.sharedNameColumns(true);

  getFormTag = () => ItemForm;

  getListApi = () => 'credit/credit_sectors';

  getStorageName = () => 'otto_credit_sectors_list';

  getAddGroups = () => ['credit_admin'];

  getEditGroups = () => ['credit_admin'];

  getPageTitle = () => 'Credit Sectors';

  canDownloadCsv = () => true;

  getAddText = () => 'Create Credit Sector';
});
