import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudForm, HtmlHelpers, OptionsCountry } from '@westernmilling/eu_react_shared';

import Languages from 'components/Options/Languages';

export default withAuth(withAlert()(class CountryForm extends CrudForm {
  formType = () => 'Country';

  getCrudType = () => 'entities/countries';

  successAlertMsg = () => this.successMsg('Country');

  options = () => (
    {
      country: {
        component: OptionsCountry,
        field: 'name',
        label: 'Name',
      },
      language: {
        component: Languages,
        field: 'language_uuid',
        label: 'Language',
      },
    }
  )

  modalBodyRows = () => (
    <div>
      { this.dropdownField('country') }
      { this.getTextFieldRow('code', 'Code', true, { maxLength: 2 }) }
      { this.getTextFieldRow('max_quantity', 'Max Quantity (kg)', false) }
      { this.dropdownField('language') }
    </div>
  )
}));
