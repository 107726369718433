import { OptionsBaseApi } from '@westernmilling/eu_react_shared';

export default class CounterpartyTypes extends OptionsBaseApi {
  getApiName = () => 'credit/counterparty_types';

  getApiParams = () => ({ order: 'name', query: 'active = true' });

  getDropdownLabelText = () => 'Counterparty Type';

  getDropdownFieldName = () => this.props.fieldName || 'counterparty_type_uuid';
}
