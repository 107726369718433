import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import {
  Button, Col, Collapse, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import { CaretToggle, CrudList, HtmlHelpers } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/LocationSystems';
import ItemForm from 'components/Forms/LocationSystem';

export default withAuth(withAlert()(class LocationSystems extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/location_systems';

  getListParams = () => ({ query: `location_uuid='${this.locationUuid()}'` });

  locationUuid = () => this.props.data[this.props.editItem].uuid;

  locationName = () => this.props.data[this.props.editItem].location_with_reference;

  getStorageName = () => 'otto_location_systems_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Systems';

  getAddText = () => 'Add System';

  auditLogModelType = () => 'Entities::LocationSystem';

  getTableClassSettings = () => 'otto__short_table';

  updateData = () => {
    this.setState({
      showModal: false, showBulkModal: false, editItem: -1, loading: true,
    });
    this.refreshAfterUpdate();
  }

  getForm = () => {
    const TagName = this.getFormTag();
    return this.state.showModal ? (
      <TagName
        location_uuid={this.locationUuid()}
        locationReference={this.props.data[this.props.editItem].location_reference}
        userGroups={this.state.userGroups}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItem}
        showModal={this.state.showModal}
        closeModal={this.handleCloseModal}
        updateData={this.updateData}
      />
    ) : '';
  }

  getContent = () => (
    <Modal
      className="otto__modal-l"
      size="lg"
      isOpen={this.props.showModal}
      toggle={this.props.closeModal}
      backdrop="static"
    >
      <ModalHeader toggle={this.props.closeModal}>
        {this.locationName()}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6" xs="12">
            <h4>
              Systems (
              {this.state.loading ? 'loading' : this.state.data.length}
              )
            </h4>
          </Col>
          <Col md="6" xs="12" className="text-right">
            { this.isAddAdmin() ? this.createButton() : '' }
          </Col>
        </Row>
        <hr />
        { this.indexTable() }
        { this.itemForm() }
        { this.auditLogModal() }
      </ModalBody>
    </Modal>
  );
}));
