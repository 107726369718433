import { OptionsBaseList } from '@westernmilling/eu_react_shared';

export default class TaxLocations extends OptionsBaseList {
  getDropdownLabelText = () => 'Tax Location';

  getDropdownFieldName = () => 'tax_location';

  getOptions = () => (
    [
      { value: 'Spain', label: 'Spain' },
      { value: 'EU', label: 'EU' },
      { value: 'Other', label: 'Other' },
    ]
  );
}
