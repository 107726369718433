import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { Api, CrudForm, HtmlHelpers } from '@westernmilling/eu_react_shared';
import moment from 'moment';

import CountryRiskRatings from 'components/Options/CountryRiskRatings';
import Users from 'components/Options/Users';

export default withAuth(withAlert()(class EntityReviewForm extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.entity_uuid ||= this.props.entity_uuid;
    this.state.item.financial_statements ||= false;
    this.state.item.ofac_compliant ||= false;
    this.state.item.insurance_coverage ||= false;
  }

  formType = () => 'Entity Review';

  getCrudType = () => 'entities/entity_reviews';

  successAlertMsg = () => this.successMsg('Review');

  showFields = (field) => ['true', true].includes(this.getItem()[field]);

  getModalSize = () => 'xl';

  getFieldColumns = () => 7;

  options = () => (
    {
      countryRisk: {
        component: CountryRiskRatings,
        field: 'country_risk_rating_uuid',
        label: 'Country Risk Rating',
      },
      users: {
        component: Users,
        field: 'reviewed_by_uuid',
        label: 'Reviewed By',
        props: {
          apiParams: { query: "users.active = true and roles.code = 'credit_admin'", with_roles: 'Y' },
          isRequired: true,
        },
      },
    }
  )

  checkFutureDate = (errors, checkDate, field) => {
    if (checkDate.isAfter(moment())) {
      errors.push(`${field} cannot be in the future`);
    }
    return errors;
  }

  additionalValidations = () => {
    let errors = [];
    const reviewDate = moment(this.getItem().review_date, 'DD/MM/YYYY');
    const nextReviewDate = moment(this.getItem().next_review_date, 'DD/MM/YYYY');

    errors = this.checkFutureDate(errors, reviewDate, 'Review Date');
    errors = this.checkFutureDate(
      errors,
      moment(this.getItem().financial_statement_date, 'DD/MM/YYYY'),
      'Financial Statement Date',
    );
    errors = this.checkFutureDate(errors, moment(this.getItem().ofac_last_checked, 'DD/MM/YYYY'), 'EU Last Checked');

    if (moment(this.getItem().insurance_expiration_date).isBefore(moment())) {
      errors.push('Credit Ins. Expiration Date must be in the future');
    }

    if (nextReviewDate.isBefore(reviewDate) || nextReviewDate.isSame(reviewDate)) {
      errors.push('Next Review Date must be after the Review Date');
    }

    this.setState({ errors });
    return errors;
  }

  onChange = (event) => {
    const newData = event.type === 'selectivity-selected'
      ? this.onChangeSelectivityEvent(event)
      : this.onChangeRegularEvent(event);

    const name = (event.target || {}).name || '';
    const value = HtmlHelpers.dig([event.target, 'value', 'value']) || event.target.value;

    if (name === 'financial_statements' && value === 'false') {
      newData.entity_name_financials = null;
      newData.financial_statement_date = null;
    }
    if (name === 'ofac_compliant' && value === 'false') {
      newData.ofac_last_checked = null;
      newData.ofac_notes = null;
    }
    if (name === 'insurance_coverage' && value === 'false') {
      newData.insurance_expiration_date = null;
      newData.insurance_notes = null;
    }

    this.setVariables({ item: newData });
  }

  financialFields = () => (
    this.showFields('financial_statements') ? (
      <div>
        {this.getDateFieldRow('financial_statement_date', 'Financial Statement Date')}
        {this.getTextFieldRow('entity_name_financials', 'Entity Name on Financials')}
        <br />
      </div>
    ) : ''
  );

  ofacFields = () => (
    this.showFields('ofac_compliant') ? (
      <div>
        {this.getDateFieldRow('ofac_last_checked', 'EU Last Checked')}
        {this.getTextareaRow('ofac_notes', 'EU Notes')}
        <br />
      </div>
    ) : ''
  );

  insuranceFields = () => (
    this.showFields('insurance_coverage') ? (
      <div>
        {this.getDateFieldRow('insurance_expiration_date', 'Credit Ins. Expiration Date')}
        {this.getTextareaRow('insurance_notes', 'Credit Insurance Notes')}
        <br />
      </div>
    ) : ''
  );

  modalBodyRows = () => (
    <div>
      {this.getDateFieldRow('review_date', 'Review Start Date')}
      {this.dropdownField('users')}
      {this.getDateFieldRow('next_review_date', 'Next Review', false)}
      {this.getTextFieldRow('current_ratio', 'Current Ratio', false)}
      {this.getNumericFieldRow('tangible_net_worth', 'Tangible Net Worth', false, { min: 0, max: 99000000000, step: 1 })}
      {this.getNumericFieldRow('employee_count', 'Employee Count', false, { min: 0, max: 1000000, step: 1 })}
      {this.dropdownField('countryRisk')}
      {this.yesNoField('financial_statements', 'Financial Statements')}
      {this.financialFields()}
      {this.yesNoField('ofac_compliant', 'EU Compliant')}
      {this.ofacFields()}
      {this.yesNoField('insurance_coverage', 'Credit Insurance Coverage')}
      {this.insuranceFields()}
      {this.getTextareaRow('review_notes', 'Review Notes')}
    </div>
  )
}));
