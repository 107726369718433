import { ColumnHelpers } from '@westernmilling/eu_react_shared';

const Columns = (context) => [
  {
    Header: 'Created On',
    accessor: 'created_on',
    className: 'text-center',
    width: 120,
  },
  {
    Header: 'Created By',
    accessor: 'user_name',
    className: 'text-left',
  },
  {
    Header: 'Reference Type',
    accessor: 'credit_reference_type',
    className: 'text-left',
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    className: 'text-left',
    width: 1000,
  },
  ColumnHelpers.auditLogLink(context),
  ColumnHelpers.columnEditButton(context),
];

export default Columns;
