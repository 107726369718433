import { withAuth } from '@okta/okta-react';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/Countries';
import ItemForm from 'components/Forms/Country';

export default withAuth(class CountriesListContainer extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/countries';

  getStorageName = () => 'otto_countries_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => 'Countries';

  getAddText = () => 'Create Country';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'Entities::Country';

  getWidthCenter = () => 8;

  getWidthLeft = () => 2;

  getWidthRight = () => 2;
});
