import React from 'react';
import { withAuth } from '@okta/okta-react';
import { withAlert } from 'react-alert';
import { CrudList } from '@westernmilling/eu_react_shared';

import Columns from 'helpers/columns/EntityContacts';
import ItemForm from 'components/Forms/EntityContact';
import ContactNotifications from 'components/Tables/ContactNotifications';

export default class EntityContacts extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'entities/entity_contacts';

  getListParams = () => ({ entity_uuid: this.props.uuid });

  getStorageName = () => 'otto_entity_contacts_list';

  getAddGroups = () => ['entities_admin'];

  getEditGroups = () => ['entities_admin'];

  getPageTitle = () => `Contacts (${this.state.loading ? 'loading' : this.state.data.length})`;

  getAddText = () => 'Add Contact';

  auditLogModelType = () => 'Entities::Contact';

  getTableClassSettings = () => 'otto__short_table';

  getContent = () => this.contentCollapsible();

  createButton = () => this.createButtonSmall();

  additionalFormProps = () => ({ entity_uuid: this.props.uuid });

  canDownloadCsv = () => false;

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  additionalContent = () => (
    this.state.showModalNotification ? (
      <ContactNotifications
        auth={this.props.auth}
        data={this.state.data}
        type={this.state.type}
        editItem={this.state.selectedItemNotification}
        showModal={this.state.showModalNotification}
        closeModal={() => this.handleCloseModal('Notification')}
        userGroups={this.state.userGroups}
        updateData={this.updateData}
      />
    ) : ''
  );
}
